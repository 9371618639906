import { standardIntervalLabels, standardIntervals } from "../constants";
import { TimeInterval } from "../types";

export const getMillisecondsAsInterval = (ms: number): TimeInterval => {
    for(let intervalName in standardIntervals) {
        const standardInterval = standardIntervals[intervalName];
        if(ms >= standardInterval.ms) {
            const amountOfUnit = Math.floor(ms / standardInterval.ms);
            const roundedResolution = amountOfUnit * standardInterval.ms;
            return {size: amountOfUnit, ms: roundedResolution, unit: standardInterval.unit};
        }
    }
    return {size: ms, ms: ms, unit: "millisecond"};
}

export const getIntervalLabel = (interval: TimeInterval) => {
    return `${interval.size}${standardIntervalLabels[interval.unit]}`;
}