import React from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { AccessTime, AddCircle, Close, GridView } from "@mui/icons-material";
import { BIRTHDAY_LISTS_ID, BatchInput, Birthday, EDIT_BIRTHDAY_ID, EVENTS_ID, EventDetails, NEW_BIRTHDAY_ID, View } from "../../shared";
import { BirthdayListCard, ButtonSliderSwitch, Calendar, CheckboxInput, MainButton, ScreenLoader, SmallButton, TextInput } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import "./birthdaylistsview.scss";

let firstLoad = true;
let save = false;

class BirthdayListsView extends View {
    id = BIRTHDAY_LISTS_ID;
    route = "/event/:eventId/birthday";
	defaultRoute = false;
	authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENTS_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [event, setEvent] = React.useState<EventDetails>();
        const [birthdays, setBirthdays] = React.useState<Array<Birthday>>([]);

        const params = this.params = useParams();
        const api = React.useContext(ApiContext);
        const { goTo, views } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {

            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return (error: any) => {
                return response;
            };
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.event.getEventDetails(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(undefined),
            });
        }

        const loadBirthdays = async (eventId: string, sessionId: string) => {
            const failableBirthdays = await api.producer.getBirthdays(eventId, sessionId);
            return failableBirthdays.match({
                success: birthdays => birthdays,
                failure: handleError([]),
            });
        }

        const loadBirthday = (birthdayId: string) => {
            return () => api.producer.getBirthday(event?.id ?? "", event?.sessions?.[0].id ?? "", birthdayId).then(failableBirthday => failableBirthday.match({
                    success: birthday => birthday,
                    failure: handleError(undefined),
                })
            );
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(event => {
                setEvent(event);
                loadBirthdays(eventId, event?.sessions?.[0].id ?? "").then(birthdays => {
                    setBirthdays(birthdays);
                    firstLoad = false;
                });
            })
        }

        React.useEffect(init, []);

		return (firstLoad ?
			<ScreenLoader /> : <div id="birthday-lists">
                <div id="birthday-lists-add"><MainButton content={<><AddCircle /> Adicionar lista</>} onClick={async () => { goTo(views[NEW_BIRTHDAY_ID], { eventId: params["eventId"] ?? "" }) }} /></div>
                {birthdays.map(birthday => <BirthdayListCard key={birthday.id} birthday={birthday} edit={() => { goTo(views[EDIT_BIRTHDAY_ID], { eventId: params["eventId"] ?? "", birthdayId: birthday.id || "" }); }} lazyLoadBirthdayInvitees={loadBirthday(birthday.id ?? "")} />)}
			</div>
		);
	}
}

export { BirthdayListsView };