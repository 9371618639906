import React from "react";
import { useParams } from "react-router-dom";
import { ApiProducerError, BIRTHDAY_LISTS_ID, BirthdayInput, EVENTS_ID, EventDetails, NEW_BIRTHDAY_ID, SIGNIN_ID, View } from "../../shared";
import { ScreenLoader } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import { BirthdayForm } from "./birthdayform";
import "./newbirthdayview.scss";

class NewBirthdayView extends View {
    id = NEW_BIRTHDAY_ID;
    route = "/event/:eventId/birthday/new";
	defaultRoute = false;
	authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[BIRTHDAY_LISTS_ID], { eventId: this.params?.["eventId"] ?? "" }); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [event, setEvent] = React.useState<EventDetails>();

        const params = this.params = useParams();

        const api = React.useContext(ApiContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {
                goTo(views[EVENTS_ID]);
            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return (error: ApiProducerError) => {
                switch(error.errorType) {
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                        break;
                }
                return response;
            }
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.producer.getEvent(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(null)
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(event => {
                if(!!event) {
                    setEvent(event);
                    setFirstLoad(false);
                } else {
                    goTo(views[EVENTS_ID]);
                }
            });
        }

        React.useEffect(init, []);

        const addEvent = async (birthdayData: BirthdayInput) => {

            api.producer.addBirthday(event?.id ?? "", event?.sessions?.[0].id ?? "", birthdayData);
        }

		return (firstLoad ?
			<ScreenLoader /> : <div id="new-birthday">
                <BirthdayForm name="" customerEmail="" code="" active={true} limit={0} benefits={[]} allowedBatches={[]} onSubmit={addEvent} />
			</div>
		);
	}
}

export { NewBirthdayView };