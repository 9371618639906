import React from "react";
import { Add, Close } from "@mui/icons-material";
import { MainButton, TextInput } from "../../components";
import { Benefit, BirthdayInput, Invitee } from "../../shared";
import "./birthdayform.scss";

interface BirthdayFormProps extends BirthdayInput {
    onSubmit: (data: BirthdayInput) => Promise<void>
}

const BirthdayForm = (birthday: BirthdayFormProps) => {
    const [birthdayName, setBirthdayName] = React.useState<string>(birthday.name ?? "");
    const [birthdayOwner, setBirthdayOwner] = React.useState<string>(birthday.customerEmail ?? "");
    const [codes, setCodes] = React.useState<Array<string>>(birthday.code ? [birthday.code] : []);
    const [newCode, setNewCode] = React.useState<string>("");

    const changeBirthdayNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setBirthdayName(e.currentTarget.value);
        e.preventDefault();
    }

    const changeBirthdayOwnerHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setBirthdayOwner(e.currentTarget.value);
        e.preventDefault();
    }

    const changeBirthdayCodeHandle = (index: number) => {
        return (e: React.FormEvent<HTMLInputElement>) => {
            setCodes(oldCodes => {
                oldCodes.splice(index, 1, e.currentTarget.value);
                return oldCodes;
            });
            e.preventDefault();
        }
    }

    const removeCode = (index: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            setCodes(oldCodes => {
                oldCodes.splice(index, 1);
                return oldCodes;
            });
            e.preventDefault();
        }
    }

    const changeBirthdayNewCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setNewCode(e.currentTarget.value);
        e.preventDefault();
    }

    const addCode = (e: React.MouseEvent<HTMLDivElement>) => {
        if(newCode !== "") {
            setCodes(oldCodes => [...oldCodes, newCode]);
            setNewCode("");
        }
        e.preventDefault();
    }

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await birthday.onSubmit({
            name: birthdayName,
            active: true,
            limit: 1,
            customerEmail: birthdayOwner,
            code: codes[0] ?? "",
            benefits: birthday.benefits ?? [],
            allowedBatches: birthday.allowedBatches,
        });
        e.preventDefault();
    }

    return <form id="birthday-form">
        <TextInput id="birthday-form-name-input" name="birthday-form-name" placeholder="Nome da lista" value={birthdayName} onChange={changeBirthdayNameHandle} />
        <div id="birthday-form-owner">
            <div className="birthday-form-label">Dono:</div>
            <TextInput id="birthday-form-owner-input" name="birthday-form-owner" placeholder="Dono da lista" value={birthdayOwner} onChange={changeBirthdayOwnerHandle} />
        </div>
        <div id="birthday-form-settings">
            <div id="birthday-form-codes">
                <div className="birthday-form-label">Códigos:</div>
                {codes.map((code, index) =>
                    <div key={`birthday-form-code-${index}-${code}`} className="birthday-form-code">
                        <TextInput id={`birthday-form-code-${index}`} name={`birthday-form-code-${index}`} placeholder="Código" value={code} onChange={changeBirthdayCodeHandle(index)} />
                        <div onClick={removeCode(index)}><Close /></div>
                    </div>
                )}
                <div className="birthday-form-code">
                    <TextInput id="birthday-form-code-new" name="birthday-form-code-new" placeholder="Código" value={newCode} onChange={changeBirthdayNewCodeHandle} />
                    <div onClick={addCode}><Add /></div>
                </div>
            </div>
            <div id="birthday-form-benefits">
                <div className="birthday-form-label">Benefícios:</div>
            </div>
        </div>
        {/*birthday.invitees?.map(invitee => <div id="birthday-form-invitee">{invitee.customerName}</div>)*/}
        <div id="birthday-form-cta">
            <MainButton content="Criar lista" onClick={onSubmit} />
        </div>
    </form>;
}

export { BirthdayForm };