import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faChartSimple, faFileExport, faFire } from "@fortawesome/free-solid-svg-icons";
import { AttachMoney, Numbers, Search } from "@mui/icons-material";
import { ButtonSliderSwitch, Pagination, SalesChart, ScreenLoader, TextInput, TicketSoldCard, WeekdayHeatmap } from "../../components";
import { ApiEventError, ApiProducerError, EVENTS_ID, EventDetails, SALES_DASHBOARD_ID, SIGNIN_ID, Sales, TimeInterval, View, exportToSpreadsheet, getIntervalLabel, getMillisecondsAsInterval } from "../../shared";
import { ApiContext, NavigationContext } from "../../contexts";
import { DropDownInput } from "../../components/forms/inputs/dropdowninput";
import "./salesdashboardview.scss";

enum ChartMode {
    SalesTimeline,
    WeekdayHeatmap,
    PaymentMethod,
}

enum SalesChartUnit {
    Tickets,
    Financial,
}

interface BatchDomain {
    [segmentId: string]: {
        segmentName: string,
        batches: Array<{
            batchId: string,
            batchName: string,
        }>,
    }
}

const defaultIntervals = [
    getMillisecondsAsInterval(1000 * 60 * 15), // 15min
    getMillisecondsAsInterval(1000 * 60 * 30), // 30min
    getMillisecondsAsInterval(1000 * 60 * 60), // 1h
    getMillisecondsAsInterval(1000 * 60 * 60 * 2), // 2h
    getMillisecondsAsInterval(1000 * 60 * 60 * 4), // 4h
    getMillisecondsAsInterval(1000 * 60 * 60 * 8), // 8h
    getMillisecondsAsInterval(1000 * 60 * 60 * 24), // 1D
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 2), // 2D
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 3), // 3D
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 4), // 4D
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 5), // 5D
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 7), // 1W
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 7 * 2), // 2W
    getMillisecondsAsInterval(1000 * 60 * 60 * 24 * 30), // 1M
];

class SalesDashboardView extends View {
    id = SALES_DASHBOARD_ID;
    route = "/sales/:eventId";
	defaultRoute = true;
	authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENTS_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [reload, setReload] = React.useState<number>(0);
        const [event, setEvent] = React.useState<EventDetails | null>(null);
        const [sessionSales, setSessionSales] = React.useState<Sales>([]);
        const [filteredSales, setFilteredSales] = React.useState<Sales>([]);
        const [chartMode, setChartMode] = React.useState<ChartMode>(ChartMode.SalesTimeline);
        const [salesChartUnit, setSalesChartUnit] = React.useState<SalesChartUnit>(SalesChartUnit.Tickets);
        const [batchDomain, setBatchDomain] = React.useState<BatchDomain>({});
        const [currentSegment, setCurrentSegment] = React.useState<string>();
        const [currentBatch, setCurrentBatch] = React.useState<string>();
        const [barResolution, setBarResolution] = React.useState<TimeInterval>(getMillisecondsAsInterval(3600000));
        const [selectedWindows, setSelectedWindows] = React.useState<Array<number>>([]);
        const [searchText, setSearchText] = React.useState<string>("");
        const [currentPage, setCurrentPage] = React.useState<number>(0);

        const paginationRef = React.useRef<HTMLDivElement>(null);

        const api = React.useContext(ApiContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);

        const params = this.params = useParams();

        const pageSize = 15;

        let timer: NodeJS.Timer;

        const getIds = () => {
            const eventId = params["eventId"];
            const sessionId = event?.sessions?.[0].id;

            if(!eventId) {
                goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
            }

            return { eventId: eventId ?? "", sessionId: sessionId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return (error: ApiProducerError | ApiEventError) => {
                switch(error.errorType) {
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                }
                return response;
            }
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.producer.getEvent(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(null)
            });
        }

        const loadSalesBySession = async (sessionId: string) => {
            const failableSales = await api.producer.getSalesBySession(sessionId);
            return failableSales.match({
                success: sales => sales,
                failure: handleError([])
            });
        }

        const loadSalesBySegment = async (sessionId: string, segmentId: string) => {
            const failableSales = await api.producer.getSalesBySegment(sessionId, segmentId);
            return failableSales.match({
                success: sales => sales,
                failure: handleError([])
            });
        }

        const loadSalesByBatch = async (sessionId: string, batchId: string) => {
            const failableSales = await api.producer.getSalesByBatch(sessionId, batchId);
            return failableSales.match({
                success: sales => sales,
                failure: handleError([])
            });
        }

        const loadTransferHistory = async (orderId: string) => {
            const failableHistory = await api.producer.getTransferHistory(orderId);
            return failableHistory.match({
                success: history => history,
                failure: handleError([])
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(event => {
                if(!!event && !!event.sessions && event.sessions.length > 0) {
                    setEvent(event);
                    loadSalesBySession(event.sessions[0].id).then(sales => {
                        setSessionSales(sales);
                        setFilteredSales(sales);
        
                        const window = getMaxWindow(sales);
                        const initialResolution = window.end - window.begin > 1000 * 60 * 60 * 24 ? 1000 * 60 * 60 * 24 : 60 * 1000;
                        setBarResolution(getMillisecondsAsInterval(initialResolution));
        
                        timer = setInterval(() => setReload(oldReload => (oldReload + 1) % 2), 5000);
                        setFirstLoad(false);
                    });
                } else {
                    goTo(views[EVENTS_ID]);
                }
            });
            return () => {
                if(!!timer) {
                    clearInterval(timer);
                }
            };
        }

        const getDomains = () => {
            const batchDomain: BatchDomain = {};

            for(let i = 0; i < sessionSales.length; i++) {
                const sale = sessionSales[i];
                for(let j = 0; j < sale.passes.length; j++) {
                    const pass = sale.passes[j];
                    if(!(pass.segmentId in batchDomain)) batchDomain[pass.segmentId] = { segmentName: pass.segmentName, batches: [] };
                    if(!batchDomain[pass.segmentId].batches.some(batch => batch.batchId === pass.batchId)) batchDomain[pass.segmentId].batches.push({ batchId: pass.batchId, batchName: pass.batchName });
                }
            }

            setBatchDomain(batchDomain);
        }

        const getFilteredSales = () => {
            const { sessionId } = getIds();

            if(!!sessionId) {
                (!!currentBatch ?
                    loadSalesByBatch(sessionId, currentBatch) :
                    !!currentSegment ?
                        loadSalesBySegment(sessionId, currentSegment) :
                        loadSalesBySession(sessionId).then(sales => { setSessionSales(sales); return sales;}) 
                ).then(setFilteredSales);
            }
        }

        const forceReload = () => {
            setReload(oldReload => (oldReload + 1) % 2);
            resetCurrentPage();
        }

        const resetWindows = () => {
            setSelectedWindows([]);
        }

        const resetCurrentPage = () => {
            setCurrentPage(0);
        }

        const keepScrollPosition = () => {
            const scrollPos = window.sessionStorage.getItem("scrollPos");
            const elem = paginationRef.current;
            if(!!scrollPos && !!elem) {
                window.scrollTo({ top: Math.max(0, elem.offsetTop - parseFloat(scrollPos)) });
                window.sessionStorage.removeItem("scrollPos");
            }
        }

        React.useEffect(init, []);
        React.useEffect(getDomains, [sessionSales]);
        React.useEffect(getFilteredSales, [reload]);
        React.useEffect(forceReload, [currentSegment, currentBatch]);
        React.useEffect(resetWindows, [barResolution]);
        React.useEffect(resetCurrentPage, [selectedWindows]);
        React.useEffect(resetCurrentPage, [searchText]);
        React.useEffect(keepScrollPosition, [currentPage]);

        const changeSegmentHandle = (segmentId?: string | number | readonly string[]) => {
            setCurrentBatch(undefined);
            setCurrentSegment(segmentId?.toString());
        }

        const changeBatchHandle = (batchId?: string | number | readonly string[]) => {
            setCurrentBatch(batchId?.toString());
        }

        const changeSearchTextHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setSearchText(e.currentTarget.value);
            e.preventDefault();
        }

        const getMaxWindow = (sales: Sales) => {
            let begin = Infinity;
            let end = 0;
            for(let i = 0; i < sales.length; i++) {
                const sale = sales[i];
                const boughtAt = Date.parse(sale.boughtAt);
                if(begin > boughtAt) {
                    begin = boughtAt;
                }
                if(end < boughtAt) {
                    end = boughtAt;
                }
            }
            return { begin, end };
        }

        const getCalculatedWindows = (sales: Sales) => {
            const maxWindow = getMaxWindow(sales);
            const windowInterval = maxWindow.end - maxWindow.begin;

            const targetInterval = getMillisecondsAsInterval(windowInterval / 8);
            const i = defaultIntervals.reduce((prev, cur) => {
                const ref = (cur.ms > targetInterval.ms) ? targetInterval.ms / cur.ms : cur.ms / targetInterval.ms;
                const nextRef = Math.max(prev.ref, ref);
                const nextIndex = nextRef !== prev.ref ? defaultIntervals.indexOf(cur) : prev.index;
                return {ref: nextRef, index: nextIndex};
            }, {ref: 0, index: -1}).index;

            return defaultIntervals.slice(Math.max(0, i - 4), Math.max(1, i + 1));
        }

        const isInWindow = (time: number) => {
            for(let i = 0; i < selectedWindows.length; i++) {
                if(time >= selectedWindows[i] && time <= selectedWindows[i] + barResolution.ms) {
                    return true;
                }
            }
            return false;
        }

        const finalSales = filteredSales
            .filter(sale => selectedWindows.length === 0 || isInWindow(Date.parse(sale.boughtAt)))
            .filter(sale => searchText === "" || sale.customerName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || sale.customerEmail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || sale.customerPhone.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);

        const pageSales = (page: number) => {
            const begin = page * pageSize;
            const end = begin + pageSize;

            return finalSales.slice(begin, end);
        }

        const quantity = finalSales.filter(sale => sale.orderStatus === "Completed").reduce((prev, cur) => prev + cur.passes.reduce((prev, cur) => prev + cur.quantity, 0), 0);

        const lazyLoadTransferHistory = (orderId: string) => {
            return async () => await loadTransferHistory(orderId);
        }

        const exportXls = (e: React.MouseEvent<HTMLAnchorElement>) => {
            const { sessionId } = getIds();

            const data = [["Data de compra","Usuário","E-mail","Telefone","Segmento","Lote","Código","Preço Unitário","Quantidade","Total","Método de pagamento", ""]];
            for(let i = 0; i < sessionSales.length; i++) {
                const sale = sessionSales[i];
                data.push(...sale.passes.map(
                    pass => [
                        moment(new Date(Date.parse(sale.boughtAt))).locale("pt-br").format("DD/MM/YYYY HH:mm"),
                        sale.customerName,
                        sale.customerEmail,
                        sale.customerPhone,
                        pass.segmentName,
                        pass.batchName,
                        pass.promoCodeUsed ?? "",
                        pass.price.toFixed(2), // Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(pass.price),
                        pass.quantity.toFixed(0),
                        (pass.price * pass.quantity).toFixed(2), // Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(pass.price * pass.quantity),
                        sale.paymentMethod === "Pix" ? "PIX" : sale.paymentMethod === "MercadoPago" ? "Cartão de Crédito" : "Gratuidade",
                        sale.orderStatus === "Reimbursed" ? "Estornado" : "",
                    ]
                ));
            }
            exportToSpreadsheet({"Relatório de Vendas": data}, `relatorio-de-vendas-${sessionId}`);
            e.preventDefault();
        }

        const changePage = (page: number) => {
            const elem = paginationRef.current;
            if(!!elem) {
                window.sessionStorage.setItem("scrollPos", (elem.offsetTop - window.scrollY).toString());
            }
            setCurrentPage(page);
        }

		return ( firstLoad ? <ScreenLoader /> :
			<div id="sales">
                <div id="sales-left">
                    <div id="sales-chart">
                        <div id="sales-header">
                            <div id="sales-header-image"><img src={event?.smallImageUrl} /></div>
                            <div id="sales-header-content">
                                {event?.name}
                                <span className="dashboard-text">Dashboard de Vendas</span>
                            </div>
                        </div>
                        <div id="sales-chart-info">
                            Total vendido:
                            <span className="value">{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(finalSales.filter(sale => sale.orderStatus === "Completed").reduce((prev, cur) => prev + cur.passes.reduce((prev, cur) => prev + cur.quantity * cur.price, 0), 0))}</span>
                            <span className="quantity">{`${quantity} ingresso${quantity > 1 ? "s" : ""}`}</span>
                        </div>
                        {false && <div id="sales-chart-mode-selector">
                            <ButtonSliderSwitch items={[
                                {
                                    content: <FontAwesomeIcon icon={faChartSimple} />,
                                    onClick: () => { setChartMode(ChartMode.SalesTimeline); }
                                },
                                {
                                    content: <FontAwesomeIcon icon={faFire} />,
                                    onClick: () => { setChartMode(ChartMode.WeekdayHeatmap); }
                                },
                                /*{
                                    content: <FontAwesomeIcon icon={faChartPie} />,
                                    onClick: () => { setChartMode(ChartMode.PaymentMethod); }
                                },*/
                            ]} />
                        </div>}
                        {chartMode === ChartMode.SalesTimeline ?
                            <>
                                <SalesChart key={`${barResolution.ms}-${window.innerWidth}-${window.innerHeight}`} width={document.body.clientWidth > 680 ? "592px" : `${document.body.clientWidth - 88}px`} height={document.body.clientWidth > 680 ? "296px" : `${296 - (680 - document.body.clientWidth) / 5}px`} series={filteredSales.filter(sale => sale.orderStatus === "Completed").map(sale => ({time: new Date(Date.parse(sale.boughtAt)), value: sale.passes.reduce((prev, cur) => prev + cur.quantity * (salesChartUnit === SalesChartUnit.Tickets ? 1 : cur.price), 0)}))} isFinancial={salesChartUnit === SalesChartUnit.Financial} resolution={barResolution} onData={setSelectedWindows} />   
                                <div id="sales-chart-resolution-selector">
                                    <ButtonSliderSwitch items={[
                                        {
                                            content: <Numbers sx={{ fontSize: "14px" }} />,
                                            onClick: () => { setSalesChartUnit(SalesChartUnit.Tickets); },
                                        },
                                        {
                                            content: <AttachMoney sx={{ fontSize: "14px" }} />,
                                            onClick: () => { setSalesChartUnit(SalesChartUnit.Financial); },
                                        },
                                    ]} />
                                    {getCalculatedWindows(filteredSales).length > 0 && <ButtonSliderSwitch items={getCalculatedWindows(filteredSales).map(interval => ({
                                        content: getIntervalLabel(interval),
                                        onClick: () => { setBarResolution(interval); }
                                    }))} />}
                                </div>
                            </> :
                            chartMode === ChartMode.WeekdayHeatmap ?
                                <WeekdayHeatmap width={document.body.clientWidth > 680 ? "560px" : `${document.body.clientWidth - 88}px`} data={filteredSales.filter(sale => sale.orderStatus === "Completed").map(sale => ({time: new Date(Date.parse(sale.boughtAt)), value: sale.passes.reduce((prev, cur) => prev + cur.quantity, 0)}))} /> :
                                <></>
                        }
                    </div>
                </div>
                <div id="sales-list">
                    <div id="sales-list-export"><a onClick={exportXls}>Exportar (.xls) <FontAwesomeIcon icon={faFileExport} /></a></div>
                    <div id="sales-list-settings">
                        <div id="sales-list-settings-type">
                            {Object.keys(batchDomain).length > 0 && <>
                                <div id="sales-list-settings-segment">
                                    <DropDownInput id="input-sales-segment" name="sales-segment" placeholder="Segmento:" unselectedLabel="Todos" width="290px" height="32px" items={[
                                        {
                                            value: undefined,
                                            text: "Todos",
                                        },
                                        ...Object.keys(batchDomain).map(segmentId => ({
                                            value: segmentId,
                                            text: batchDomain[segmentId].segmentName,
                                        }))
                                    ]} selectedValue={currentSegment} onChange={changeSegmentHandle} />
                                </div>
                                <div id="sales-list-settings-batch">
                                    <DropDownInput id="input-sales-batch" name="sales-batch" enabled={!!currentSegment && currentSegment in batchDomain} placeholder="Lote:" unselectedLabel="Todos" width="290px" height="32px" items={[
                                        {
                                            value: undefined,
                                            text: "Todos",
                                        },
                                        ...(!!currentSegment && currentSegment in batchDomain ? batchDomain[currentSegment].batches : []).map(batch => ({
                                            value: batch.batchId,
                                            text: batch.batchName,
                                        }))
                                    ]} selectedValue={currentBatch} onChange={changeBatchHandle} />
                                </div>
                            </>}
                        </div>
                        <div id="sales-list-settings-search-container"><TextInput id="input-sales-search" name="sales-search" prefix={<Search sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Procure um comprador..." value={searchText} onChange={changeSearchTextHandle} /></div>
                    </div>
                    <div id="sales-list-customers">
                        {finalSales.length === 0 ? <>Nenhum comprador encontrado.</> : pageSales(currentPage).map(sale => <TicketSoldCard key={JSON.stringify(sale)} sale={sale} lazyLoadTransferHistory={lazyLoadTransferHistory(sale.orderId)} />)}
                    </div>
                    <div ref={paginationRef} id="sales-list-pagination">
                        <Pagination currentPage={currentPage} pageCount={Math.ceil(finalSales.length / pageSize)} onChange={changePage} style={{"--size": "250px"}} />
                    </div>
                </div>
			</div>
		);
	}
}

export { SalesDashboardView };