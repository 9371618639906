import React from "react";
import { Info } from "luxon";
import "./style.scss";

interface HeatmapTable {
    [weekday: number]: {
        [hour: number]: number
    }
}

interface DataPoint {
    value: number,
    time: Date,
}

type DataPoints = Array<DataPoint>;

interface WeekdayHeatmapProps {
    data: DataPoints,
    width?: string | number,
    height?: string | number,
    onData?: (weekday: number, hour: number) => void,
}

const WeekdayHeatmap = ({ data, width, height, onData }: WeekdayHeatmapProps) => {

    const [currentHoverElement, setCurrentHoverElement] = React.useState<[number, number] | null>(null);
    const [currentSelectedElements, setCurrentSelectedElements] = React.useState<Array<[number, number]>>([]);

    const sendData = () => {
        if(onData !== undefined) {
            onData(0, 0);
        }
    }

    React.useEffect(sendData, [currentHoverElement]);
    React.useEffect(sendData, [currentSelectedElements]);

    const generateHeatmap = () => {
        const heatmapTable: HeatmapTable = {};
        let maxHeat = 0;

        for(let i = 0; i < data.length; i++) {
            const point = data[i];
            const weekday = point.time.getDay();
            const hour = point.time.getHours();

            if(!(weekday in heatmapTable)) {
                heatmapTable[weekday] = {};
            }

            if(!(hour in heatmapTable[weekday])) {
                heatmapTable[weekday][hour] = 0;
            }

            maxHeat = Math.max(maxHeat, heatmapTable[weekday][hour] += point.value);
        }
        return { heatmapTable, maxHeat };
    }

    const { heatmapTable, maxHeat } = generateHeatmap();

    return <div className="heatmap-table" style={{ "--heatmap-width": width ?? "335px", "--heatmap-height": height ?? "calc(var(--heatmap-width) * 8 / 25)" } as React.CSSProperties}>
        {Array.from(Array(7).keys()).map(weekday =>
            <>
                <div className="heatmap-table-axis-label">{Info.weekdays("short", { locale: "pt-br" })[(((weekday - 1) % 7) + 7) % 7][0]}</div>
                {Array.from(Array(24).keys()).map(hour =>
                    <div className="heatmap-table-item" style={{ opacity: maxHeat !== 0 && weekday in heatmapTable ? (heatmapTable[weekday]?.[hour] ?? 0) / maxHeat : 0 }} title={`${Info.weekdays("long", { locale: "pt-br" })[(((weekday - 1) % 7) + 7) % 7]} ${hour}:00 - ${hour + 1}:00: ${weekday in heatmapTable ? heatmapTable[weekday]?.[hour]?.toString() : "0"}`}></div>
                )}
            </>
        )}
        <div></div>
        {Array.from(Array(24).keys()).map(hour =>
            <div className="heatmap-table-axis-label">{hour < 10 ? `0${hour}`: hour}</div>
        )}
    </div>;
}

export { WeekdayHeatmap };