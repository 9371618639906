import { TimeInterval } from "../types";

export const standardIntervals: {[intervalName: string]: TimeInterval} = {
    month: {
        size: 1,
        ms: 2592000000,
        unit: "month",
    },
    week: {
        size: 1,
        ms: 604800000,
        unit: "week",
    },
    day: {
        size: 1,
        ms: 86400000,
        unit: "day",
    },
    hour: {
        size: 1,
        ms: 3600000,
        unit: "hour",
    },
    minute: {
        size: 1,
        ms: 60000,
        unit: "minute",
    },
};

export const standardIntervalLabels: {[unit: string]: string} = {
    "month": "M",
    "week": "S",
    "day": "D",
    "hour": "h",
    "minute": "min",
    "second": "s",
    "millisecond": "ms",
};