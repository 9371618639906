import React from "react";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import "./style.scss";
import { ArrowBackIos, ArrowForwardIos, Circle } from "@mui/icons-material";

interface CommonCalendarProps {
}

interface RangeCalendarProps extends CommonCalendarProps {
    range: true,
    beginSelection?: Date,
    endSelection?: Date,
    onChange?: (beginDate: Date, endDate: Date) => void;
}

interface SingleDateCalendarProps extends CommonCalendarProps {
    range?: false,
    selectedDate?: Date,
    onChange?: (date: Date) => void;
}

type CalendarProps = RangeCalendarProps | SingleDateCalendarProps;

const Calendar = (props: CalendarProps) => {
    const [beginSelection, setBeginSelection] = React.useState<Date>(!!props.range ? props.beginSelection ?? new Date() : props.selectedDate ?? new Date());
    const [endSelection, setEndSelection] = React.useState<Date>(!!props.range ? props.endSelection ?? new Date() : props.selectedDate ?? new Date());
    const [currentMonth, setCurrentMonth] = React.useState<number>(beginSelection.getMonth());
    const [currentYear, setCurrentYear] = React.useState<number>(beginSelection.getFullYear());
    const [monthSelection, setMonthSelection] = React.useState<boolean>(false);
    const [yearSelection, setYearSelection] = React.useState<number>(-1);

    const propagateSelection = () => {
        if(!!props.range && !!props.onChange) {
            props.onChange(beginSelection, endSelection);
        } else if(!props.range && !!props.onChange) {
            props.onChange(beginSelection);
        }
    }

    React.useEffect(propagateSelection, [beginSelection, endSelection]);

    const prevMonth = (e: React.MouseEvent<HTMLButtonElement>) => {
        const prevMonthDate = new Date(currentYear, currentMonth - 1, 1);
        setCurrentMonth(prevMonthDate.getMonth());
        setCurrentYear(prevMonthDate.getFullYear());
        e.preventDefault();
    }

    const nextMonth = (e: React.MouseEvent<HTMLButtonElement>) => {
        const nextMonthDate = new Date(currentYear, currentMonth + 1, 1);
        setCurrentMonth(nextMonthDate.getMonth());
        setCurrentYear(nextMonthDate.getFullYear());
        e.preventDefault();
    }

    const currentSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
        setCurrentMonth(beginSelection.getMonth());
        setCurrentYear(beginSelection.getFullYear());
        e.preventDefault();
    }

    const showMonthsTable = (e: React.MouseEvent<HTMLDivElement>) => {
        setYearSelection(-1);
        setMonthSelection(true);
        e.preventDefault();
    }

    const showYearsTable = (e: React.MouseEvent<HTMLDivElement>) => {
        setMonthSelection(false);
        setYearSelection(currentYear);
        e.preventDefault();
    }

    const sameDay = (day1: Date, day2: Date) => {
        return day1.getDate() === day2.getDate() &&
            day1.getMonth() === day2.getMonth() &&
            day1.getFullYear() === day2.getFullYear();
    }

    const dayIsInBetween = (day: Date, startDay: Date, endDay: Date) => {
        return day.getDate() > startDay.getDate() &&
            day.getMonth() > startDay.getMonth() &&
            day.getFullYear() > startDay.getFullYear() &&
            day.getDate() < endDay.getDate() &&
            day.getMonth() < endDay.getMonth() &&
            day.getFullYear() < endDay.getFullYear();
    }

    const pickDate = (date: Date) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            if(currentMonth !== date.getMonth()) {
                setCurrentMonth(date.getMonth());
            }

            if(currentYear !== date.getFullYear()) {
                setCurrentYear(date.getFullYear());
            }

            if(!props.range) {
                setBeginSelection(date);
                setEndSelection(date);
            }
            e.preventDefault();
        }
    }

    const getCalendarTable = () => {
        let cursorDay = new Date(currentYear, currentMonth, 1);
        const endMonthDay = new Date(currentYear, currentMonth + 1, 1).getTime();

        const columns = [
            <div className="calendar-row">
                <div className="calendar-weekday">D</div>
                <div className="calendar-weekday">S</div>
                <div className="calendar-weekday">T</div>
                <div className="calendar-weekday">Q</div>
                <div className="calendar-weekday">Q</div>
                <div className="calendar-weekday">S</div>
                <div className="calendar-weekday">S</div>
            </div>
        ];
        for(let i = 0; i <= 5; i++) {
            const row = [];
            for(let j = 0; j <= 6; j++) {
                if(i === 0 && cursorDay.getDay() > j) {
                    const pastDay = new Date(cursorDay.getTime() - (cursorDay.getDay() - j) * 86400000);
                    const isBeginDay = sameDay(pastDay, beginSelection);
                    const isEndDay = sameDay(pastDay, endSelection);
                    const inRange = dayIsInBetween(pastDay, beginSelection, endSelection);

                    row.push(
                        <div key={v4()} className={`calendar-day not-current-month ${inRange && !isBeginDay && !isEndDay ? "range" : ""}`} onClick={pickDate(pastDay)}>
                            <div className={isBeginDay || isEndDay ? "selected" : ""}>{pastDay.getDate()}</div>
                        </div>
                    );
                } else {
                    const isBeginDay = sameDay(cursorDay, beginSelection);
                    const isEndDay = sameDay(cursorDay, endSelection);
                    const inRange = dayIsInBetween(cursorDay, beginSelection, endSelection);

                    if(cursorDay.getTime() >= endMonthDay) {
                        row.push(
                            <div key={v4()} className={`calendar-day not-current-month ${inRange && !isBeginDay && !isEndDay ? "range" : ""}`} onClick={pickDate(cursorDay)}>
                                <div className={isBeginDay || isEndDay ? "selected" : ""}>{cursorDay.getDate()}</div>
                            </div>
                        );
                    } else {
                        row.push(
                            <div key={cursorDay.getDate()} className={`calendar-day ${inRange && !isBeginDay && !isEndDay ? "range" : ""}`} onClick={pickDate(cursorDay)}>
                                <div className={isBeginDay || isEndDay ? "selected" : ""}>{cursorDay.getDate()}</div>
                            </div>
                        );
                    }

                    cursorDay = new Date(cursorDay.getTime() + 86400000);
                }
            }

            columns.push(<div key={`calendar-row-${cursorDay.getMonth()}-${cursorDay.getFullYear()}-${i}`} className="calendar-row">{row}</div>);
            if(cursorDay.getTime() >= endMonthDay) {
                break;
            }
        }

        return columns;
    }

    const selectMonth = (month: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            setCurrentMonth(month);
            setMonthSelection(false);
            e.preventDefault();
        }
    }

    const getMonthsTable = () => {
        const columns = [];
        const months = Array.from(Array(12).keys()).map(month => DateTime.local(currentYear, month + 1, 1).toFormat("LLL", { locale: "pt-br" }).replace(".", ""));
        for(let i = 0; i < 4; i++) {
            const row = [];
            for(let j = 0; j < 3; j++) {
                row.push(
                    <div key={`month-${i * 3 + j}`} className={`calendar-month`} onClick={selectMonth(i * 3 + j)}>
                        <div className={currentMonth === i * 3 + j ? "selected" : ""}>{months[i * 3 + j]}</div>
                    </div>
                );
            }
            columns.push(<div key={`month-row-${i}`} className="calendar-month-row">{row}</div>);
        }
        return columns;
    }

    const selectYear = (year: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            setCurrentYear(year);
            setYearSelection(-1);
            e.preventDefault();
        }
    }

    const getYearsTable = (year: number) => {
        let cursorYear = year - (year % 12);

        const columns = [
            <div className="calendar-year-header">
                <div className="calendar-year-range" onClick={() => { setYearSelection(year - 12); }}><ArrowBackIos sx={{fontSize: "12px"}} /> {cursorYear - 12}-{cursorYear - 1}</div>
                <div className="calendar-year-range" onClick={() => { setYearSelection(year + 12); }}>{cursorYear + 12}-{cursorYear + 23} <ArrowForwardIos sx={{fontSize: "12px"}} /></div>
            </div>
        ];

        for(let i = 0; i < 4; i++) {
            const row = [];
            for(let j = 0; j < 3; j++) {
                row.push(
                    <div key={`year-${cursorYear}`} className={`calendar-year`} onClick={selectYear(cursorYear)}>
                        <div className={currentYear === cursorYear ? "selected" : ""}>{cursorYear}</div>
                    </div>
                );
                cursorYear++;
            }
            columns.push(<div key={`year-row-${cursorYear}`} className="calendar-year-row">{row}</div>);
        }
        return columns;
    }

    return <div className="calendar">
        <div className={`calendar-header ${yearSelection !== -1 || monthSelection ? "hide" : ""}`}>
            <div className="calendar-current">
                <div className="calendar-current-month" onClick={showMonthsTable}>{DateTime.local(currentYear, currentMonth + 1, 1).toFormat("LLLL", { locale: "pt-br" })}</div>
                <div className="calendar-current-year" onClick={showYearsTable}>{DateTime.local(currentYear, currentMonth + 1, 1).toFormat("yy", { locale: "pt-br" })}</div>
            </div>
            <div className="calendar-nav">
                <button onClick={prevMonth}><ArrowBackIos sx={{fontSize: "14px"}} /></button>
                <button onClick={currentSelection}><Circle sx={{fontSize: "8px"}} /></button>
                <button onClick={nextMonth}><ArrowForwardIos sx={{fontSize: "14px"}} /></button>
            </div>
        </div>
        {yearSelection !== -1 ?
            getYearsTable(yearSelection) :
            monthSelection ?
                getMonthsTable() :
                getCalendarTable()
        }
    </div>;
}

export { Calendar };