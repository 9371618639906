import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./dropdowninput.scss";

interface DropDownItem {
    value?: string | number | readonly string[],
    text: string,
}

export interface DropDownInputProps {
    id: string,
    name: string,
    width?: string,
    height?: string,
    fontSize?: string,
    placeholder?: string,
    unselectedLabel?: string,
    selectedValue?: string | number | readonly string[],
    items: Array<DropDownItem>,
    multiple?: boolean,
    enabled?: boolean,
    onChange: (value: string | number | readonly string[] | undefined) => void,
};

const DropDownInput = (props: DropDownInputProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const selectedValue = props.selectedValue;

    const toggleOptions = (e: React.MouseEvent<HTMLInputElement>) => {
        setExpanded(oldExpanded => !oldExpanded);
        e.preventDefault();
    }

    const onChange = (value: string | number | readonly string[] | undefined) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            if(!props.multiple) setExpanded(false);
            props.onChange(value);
            e.preventDefault();
        }
    }

    return (
        <div className={expanded ? "expanded" : ""} style={{
            "--select-width": props.width ?? "380px",
            "--select-height": props.height ?? "60px",
            "--font-size": props.fontSize ?? "14px",
        } as React.CSSProperties}>
            {expanded && <div className="form-input-dropdown-click-out" onClick={() => { setExpanded(false); }}></div>}
            <div className="form-input-dropdown">
                <div className="form-input-dropdown-content" onClick={toggleOptions}>
                    <div className="form-input-dropdown-content-selected-value">
                        {props.placeholder && <span className="placeholder">{props.placeholder}&nbsp;</span>}
                        {selectedValue !== undefined && selectedValue !== "" ?
                            (Array.isArray(selectedValue) ?
                                props.items.filter(item => selectedValue.indexOf(item.value) !== -1).join(", ") :
                                props.items.find(item => item.value === selectedValue)?.text) :
                            <span className="placeholder"><i>{props.unselectedLabel ?? "Não selecionado"}</i></span>
                        }
                    </div>
                    <div className="form-input-dropdown-content-chevrons">
                        <FontAwesomeIcon icon={faChevronUp} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>
                <div className="form-input-dropdown-options">
                    {props.items.map((item, i) => <div key={item.value?.toString() ?? item.text} className="form-input-dropdown-options-item" onClick={onChange(item.value)}>
                        {item.text}
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export { DropDownInput };