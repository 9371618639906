import React from "react";
import { DateTime } from "luxon";
import { Birthday, Invitee } from "../../shared";
import { CampaignOutlined, Close, Edit } from "@mui/icons-material";
import "./birthdaylistcard.scss";
import { NavigationContext } from "../../contexts";

export interface BirthdayListCardProps {
    birthday: Birthday,
    lazyLoadBirthdayInvitees: () => Promise<Birthday | undefined>,
    edit: () => void,
};

const BirthdayListCard = (props: BirthdayListCardProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [birthday, setBirthday] = React.useState<Birthday>(props.birthday);

    const loadBirthdayInvitees = () => {
        if(expanded) {
            props.lazyLoadBirthdayInvitees().then(birthday => {
                if(birthday) {
                    setBirthday(birthday);
                }
            });
        }
    }

    React.useEffect(loadBirthdayInvitees, [expanded]);

    const toggleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
        setExpanded(oldExpanded => !oldExpanded);
        e.preventDefault();
    }

    const getInviteesGroupedByCustomer = () => {
        const sales: {[customerEmail: string]: Array<Invitee>} = {};

        if(birthday.invitees) {
            for(let i = 0; i < birthday.invitees.length; i++) {
                const invitee: Invitee = birthday.invitees[i];
                if(!(invitee.customerEmail in sales)) {
                    sales[invitee.customerEmail] = [];
                }
                sales[invitee.customerEmail].push(invitee);
            }
        }
        return sales;
    }

    const salesGroupedByCustomer = getInviteesGroupedByCustomer();

    const brl = Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" })
    const quantity =  birthday.invitees?.reduce((prev, cur) => prev + cur.passes.reduce((prev, cur) => prev + cur.quantity, 0), 0) ?? 0;
    const totalSoldAmount = birthday.invitees?.reduce((prev, cur) => prev + cur.passes.reduce((prev, cur) => prev + cur.quantity * cur.price, 0), 0) ?? 0;

    return (
        <div className={`birthday-list-card ${expanded ? "expanded" : ""}`} onClick={toggleExpand}>
            <div className="birthday-list-card-title">
                {birthday.name}
                {expanded && <div className="birthday-list-card-cta">
                    <button onClick={() => {}}><CampaignOutlined /></button>
                    <button onClick={props.edit}><Edit /></button>
                    <button onClick={() => {}}><Close /></button>
                </div>}
            </div>
            <div className="birthday-list-card-sale">
                {expanded ?
                    <>
                        {Object.values(salesGroupedByCustomer).length > 0 ? Object.values(salesGroupedByCustomer).map(customerSales => <div className="birthday-list-card-sale-segment">
                            <div className="birthday-list-card-sale-segment-name">{customerSales[0].customerName}</div>
                            {customerSales.map(sale => sale.passes).flat().map(pass => <div className="birthday-list-card-sale-segment-batch">
                                <div className="birthday-list-card-sale-segment-batch-bought">{pass.quantity}x {pass.batchName} <span className="light">({brl.format(pass.price)})</span></div>
                                <div className="birthday-list-card-sale-segment-batch-amount">{brl.format(pass.quantity * pass.price)}</div>
                            </div>)}
                        </div>) : <div>Nenhuma compra efetuada até o momento para esta lista</div>}
                    </> : <>
                        <div className="birthday-list-card-sale-quantity">{`${quantity} ingresso${quantity > 1 ? "s" : ""}`}</div>
                        <div className="birthday-list-card-sale-financial">{brl.format(totalSoldAmount)}</div>
                    </>
                }
            </div>
        </div>
    );
};

export { BirthdayListCard };