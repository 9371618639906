import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Lock, Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import { CheckboxInput, MainButton, TextInput } from "../../components";
import { ApiAuthError, Auth, EVENTS_ID, FORGOT_PASSWORD_ID, SIGNIN_ID, SIGNUP_ID, View, executeRecaptcha, fbLogin } from "../../shared";
import { ApiContext, AuthContext, NavigationContext } from "../../contexts";
import "./signinview.scss";

class SigninView extends View {
    id = SIGNIN_ID;
    route = "/auth/signin";
	defaultRoute = true;
	authNeeded = false;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
		const [email, setEmail] = React.useState<string>("");
		const [password, setPassword] = React.useState<string>("");
		const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
		const [rememberMe, setRememberMe] = React.useState<boolean>(true);

		const { auth, setAuth } = React.useContext(AuthContext);
		const api = React.useContext(ApiContext);
		const { views, goTo, navigate, routeBuilder } = this.navigation = React.useContext(NavigationContext);

		const [searchParams] = useSearchParams();

		// hardcoded sessionIds TODO: Remove
        const freeEvent = "6646f1db-9759-4c7d-aba6-b0af3ef93ae1";
        const festivalRock = "87b12fed-03d7-484f-bc75-a57d11965672";
        const classique = "ff645e53-b6f0-4cb2-acff-dfa59f47cc5e";
        const selectedSessionId = classique;

		React.useEffect(() => {
			if(auth !== null) {
				goTo(views[EVENTS_ID]);
			}
		}, [auth]);

		const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
			setEmail(e.currentTarget.value);
			e.preventDefault();
		}

		const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
			setPassword(e.currentTarget.value);
			e.preventDefault();
		}

		const clickRememberMeHandle = (e: React.FormEvent<HTMLInputElement>) => {
			setRememberMe(oldRememberMe => !oldRememberMe);
			e.preventDefault();
		}

		const handleSignin = {
			success: (auth: Auth | null) => {
				setAuth(auth);
				if(!!auth) {
					const ref = searchParams.get("ref");
					if(!!ref && routeBuilder?.find(ref) !== "/") {
						navigate(ref);
					} else {
						goTo(views[EVENTS_ID]);
					}
				} else {
					window.alert("Login inválido.");
				}
				return Promise.resolve();
			},
			failure: (error: ApiAuthError) => {
				switch(error.errorType) {
					case "FETCH_GENERIC_ERROR":
						window.alert("Login inválido.");
						break;
					case "API_TIMEOUT_ERROR":
						window.alert("Problema na conexão.");
						break;
				}
				return Promise.resolve();
			}
		}

		const signin = async (e: React.MouseEvent<HTMLButtonElement>) => {
			await executeRecaptcha(async (token: any) => {
				const failableSignin = await api.auth.signin(token, email.trim(), password, rememberMe);
				await failableSignin.match(handleSignin);
			});
			e.preventDefault();
		}

		const signinWithFacebook = async (e: React.MouseEvent<HTMLButtonElement>) => {
			await fbLogin(async (authResponse: any) => {
				await executeRecaptcha(async (token: any) => {
					const failableSignin = await api.auth.signinWithFacebook(token, authResponse.accessToken, rememberMe);
					await failableSignin.match(handleSignin);
				});
			});
			e.preventDefault();
		}

		const googleLogin = useGoogleLogin({
			flow: "auth-code",
			onSuccess: async codeResponse => {
				await executeRecaptcha(async (token: any) => {
					const failableSignin = await api.auth.signinWithGoogle(token, codeResponse.code, rememberMe);
					await failableSignin.match(handleSignin);
				});
			},
			onError: console.log,
		});

		const signinWithGoogle = async (e: React.MouseEvent<HTMLButtonElement>) => {
			googleLogin();
			e.preventDefault();
		}

		return (
			<div id="signin">
				<div id="signin-form">
					<TextInput id="signin-email" name="email" value={email} prefix={<Mail sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="E-mail" onChange={changeEmailHandle} />
					<TextInput id="signin-password" name="password" password={!passwordVisible} value={password} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} suffix={passwordVisible ? <VisibilityOff sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setPasswordVisible(false); }} /> : <Visibility sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setPasswordVisible(true); }} /> } placeholder="Senha" onChange={changePasswordHandle} />
					<div id="signin-remember-me-container">
						<CheckboxInput id="signin-remember-me" name="remember-me" checked={rememberMe} text="Manter-me logado" onClick={clickRememberMeHandle} />
					</div>
					<MainButton content="Entrar" onClick={signin} />
					<div id="signin-forgot-password">Esqueceu sua senha? <a onClick={() => goTo(views[FORGOT_PASSWORD_ID])}>A gente resolve!</a></div>
					{false && <div id="signin-social">
						<div id="signin-social-separator">
							<hr />
							<div>ou continue com</div>
							<hr />
						</div>
						<div id="signin-social-buttons">
							<button onClick={signinWithFacebook}><img src="/images/facebook.svg" /></button>
							<button onClick={signinWithGoogle}><img src="/images/google.svg" /></button>
							{false && <button><img src="/images/apple.svg" /></button>}
						</div>
					</div>}
					<div id="signin-signup-text">Não tem uma conta? <a onClick={() => goTo(views[SIGNUP_ID])}>Cadastre-se</a></div>
				</div>
			</div>
		);
	}
}

export { SigninView };