import React from "react";
import { ApiContext } from "./api";
import { Customer } from "../shared";
import { AuthContext } from "./auth";

type Dispatch<A> = (value: A) => void;
export type CustomerState = {
    customer: Customer | null,
    setCustomer: Dispatch<React.SetStateAction<Customer | null>>,
};

const initialCustomerState: CustomerState = {
    customer: null,
    setCustomer: () => {},
};

export const CustomerContext = React.createContext(initialCustomerState);

export const CustomerProvider = ({ children } : { children: React.ReactNode}) => {
    const [customer, setCustomer] = React.useState(initialCustomerState.customer);

    const api = React.useContext(ApiContext);
    const { auth } = React.useContext(AuthContext);

    const loadCustomer = () => {
    }

    React.useEffect(loadCustomer, []);
    React.useEffect(loadCustomer, [auth]);

    return (
        <CustomerContext.Provider value={{ customer, setCustomer }}>
            {children}
        </CustomerContext.Provider>
    );
};