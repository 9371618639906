export const initRecaptchaSdk = async () => {
    return new Promise(resolve => {
        // load recaptcha script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?render=6Leh-18lAAAAAGkSBxyE2qYga-zaoXbq57QNR9J7";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-script'));

        resolve();
    });
}