import React from "react";
import { DateTime } from "luxon";
import { AccessTime, Edit, LocationOn } from "@mui/icons-material";
import { Calendar, MainButton } from "../../components";
import "./eventform.scss";
import { useLoadScript } from "@react-google-maps/api";

interface EventFormData {
    name?: string,
    bigImageUrl?: string,
    startDate?: string,
    endDate?: string,
    venue?: {
        name?: string,
        street?: string,
    },
    shortDescription?: string,
    longDescription?: string,
}

interface EventFormProps extends EventFormData {
    onSubmit: (data: EventFormData) => Promise<void>
}

const EventForm = (event: EventFormProps) => {
    const [eventName, setEventName] = React.useState<string>(event.name ?? "");
    const [imageCover, setImageCover] = React.useState<string>(event.bigImageUrl ?? "/images/default-event-bg.jpg")
    const [startDate, setStartDate] = React.useState<Date>(!!event.startDate ? new Date(Date.parse(event.startDate)) : new Date());
    const [editStartDate, setEditStartDate] = React.useState<boolean>(false);
    const [editStartTime, setEditStartTime] = React.useState<boolean>(false);
    const [endDate, setEndDate] = React.useState<Date>(!!event.endDate ? new Date(Date.parse(event.endDate)) : new Date());
    const [editEndDate, setEditEndDate] = React.useState<boolean>(false);
    const [editEndTime, setEditEndTime] = React.useState<boolean>(false);
    const [venueName, setVenueName] = React.useState<string>(event.venue?.name ?? "");
    const [venueStreet, setVenueStreet] = React.useState<string>(event.venue?.street ?? "");

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyC13QzIwyIPIXDtt20NNf4weuXtzcVcPRk",
        libraries: ["places"],
    });

    const closeAll = (e: React.MouseEvent<HTMLDivElement>) => {
        setEditStartDate(false);
        setEditStartTime(false);
        setEditEndDate(false);
        setEditEndTime(false);
        e.preventDefault();
    }

    const selectFile = (e: React.MouseEvent<HTMLDivElement>) => {
        const input = document.createElement("input");
        input.type = "file";
        input.onchange = (e) => {
            if(!!input.files && input.files.length > 0)
            setImageCover(URL.createObjectURL(input.files[0]));
            e.preventDefault();
        };
        input.click();
        e.preventDefault();
    }

    const changeEventNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setEventName(e.currentTarget.value);
        e.preventDefault();
    }

    const openStartDateCalendar = (e: React.MouseEvent<HTMLDivElement>) => {
        setEditStartTime(false);
        setEditEndDate(false);
        setEditEndTime(false);
        setEditStartDate(oldValue => !oldValue);
        e.preventDefault();
    }

    const changeStartDateHandle = (date: Date) => {
        setStartDate(oldDate => new Date(date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes(), 0));
    }

    const openStartTimeSelector = (e: React.MouseEvent<HTMLDivElement>) => {
        setEditStartDate(false);
        setEditEndDate(false);
        setEditEndTime(false);
        setEditStartTime(oldValue => !oldValue);
        e.preventDefault();
    }

    const changeStartTimeHandle = (hour: number, minute: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartTime(false);
            setStartDate(oldDate => new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), hour, minute, 0));
            e.preventDefault();
        }
    }

    const openEndDateCalendar = (e: React.MouseEvent<HTMLDivElement>) => {
        setEditStartDate(false);
        setEditStartTime(false);
        setEditEndTime(false);
        setEditEndDate(oldValue => !oldValue);
        e.preventDefault();
    }

    const changeEndDateHandle = (date: Date) => {
        setEndDate(oldDate => new Date(date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes(), 0));
    }

    const openEndTimeSelector = (e: React.MouseEvent<HTMLDivElement>) => {
        setEditStartDate(false);
        setEditStartTime(false);
        setEditEndDate(false);
        setEditEndTime(oldValue => !oldValue);
        e.preventDefault();
    }

    const changeEndTimeHandle = (hour: number, minute: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            setEditEndTime(false);
            setEndDate(oldDate => new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), hour, minute, 0));
            e.preventDefault();
        }
    }

    const changeVenueNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setVenueName(e.currentTarget.value);
        e.preventDefault();
    }

    const changeVenueStreetHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setVenueStreet(e.currentTarget.value);
        e.preventDefault();
    }

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await event.onSubmit({});
        e.preventDefault();
    }

    return <form id="event-form">
        {(editStartDate || editEndDate) && <div id="event-form-click-out" onClick={closeAll}></div>}
        <div id="event-form-image">
            <div id="event-form-edit-image" onClick={selectFile}>
                <Edit />
            </div>
            <div id="event-form-name">
                <input type="text" placeholder="Nome do evento" value={eventName} onChange={changeEventNameHandle} />
            </div>
            <img src={imageCover} />
        </div>
        <div id="event-form-date">
            <span className="icon"><AccessTime sx={{ fontSize: "18px" }} /></span>
            <div id="event-form-date-container">
                <div id="event-form-date-start">
                    <span>Início</span>
                    <div className="event-form-date-input">
                        <div>
                            <div onClick={openStartDateCalendar}>
                                {DateTime.fromJSDate(startDate).toFormat("dd/LL/yyyy", { locale: "pt-br" })}
                            </div>
                            <div className={`event-form-date-calendar ${editStartDate ? "open" : ""}`}>
                                <Calendar selectedDate={startDate} onChange={changeStartDateHandle} />
                            </div>
                        </div>
                        <div>
                            <div onClick={openStartTimeSelector}>
                                {DateTime.fromJSDate(startDate).toFormat("HH:mm", { locale: "pt-br" })}
                            </div>
                            <div className={`event-form-date-time-select ${editStartTime ? "open" : ""}`}>
                                {Array.from(Array(24).keys()).map(hour => <>
                                    <div key={`start-${hour}-0`} className={startDate.getHours() === hour && startDate.getMinutes() === 0 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 0)}>{hour < 10 ? `0${hour}` : hour}:00</div>
                                    <div key={`start-${hour}-15`} className={startDate.getHours() === hour && startDate.getMinutes() === 15 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 15)}>{hour < 10 ? `0${hour}` : hour}:15</div>
                                    <div key={`start-${hour}-30`} className={startDate.getHours() === hour && startDate.getMinutes() === 30 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 30)}>{hour < 10 ? `0${hour}` : hour}:30</div>
                                    <div key={`start-${hour}-45`} className={startDate.getHours() === hour && startDate.getMinutes() === 45 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 45)}>{hour < 10 ? `0${hour}` : hour}:45</div>
                                </>)}
                            </div>
                        </div>
                    </div>,
                </div>
                <div id="event-form-date-end">
                    <span>Fim</span>
                    <div className="event-form-date-input">
                        <div>
                            <div onClick={openEndDateCalendar}>
                                {DateTime.fromJSDate(endDate).toFormat("dd/LL/yyyy", { locale: "pt-br" })}
                            </div>
                            <div className={`event-form-date-calendar ${editEndDate ? "open" : ""}`}>
                                <Calendar selectedDate={endDate} onChange={changeEndDateHandle} />
                            </div>
                        </div>
                        <div>
                            <div onClick={openEndTimeSelector}>
                                {DateTime.fromJSDate(endDate).toFormat("HH:mm", { locale: "pt-br" })}
                            </div>
                            <div className={`event-form-date-time-select ${editEndTime ? "open" : ""}`}>
                            {Array.from(Array(24).keys()).map(hour => <>
                                    <div key={`end-${hour}-0`} className={endDate.getHours() === hour && endDate.getMinutes() === 0 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 0)}>{hour < 10 ? `0${hour}` : hour}:00</div>
                                    <div key={`end-${hour}-15`} className={endDate.getHours() === hour && endDate.getMinutes() === 15 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 15)}>{hour < 10 ? `0${hour}` : hour}:15</div>
                                    <div key={`end-${hour}-30`} className={endDate.getHours() === hour && endDate.getMinutes() === 30 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 30)}>{hour < 10 ? `0${hour}` : hour}:30</div>
                                    <div key={`end-${hour}-45`} className={endDate.getHours() === hour && endDate.getMinutes() === 45 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 45)}>{hour < 10 ? `0${hour}` : hour}:45</div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="event-form-venue">
            <span className="icon"><LocationOn sx={{ fontSize: "18px" }} /></span>
            <div id="event-form-venue-container">
                {true ?
                    <>
                        <div id="event-form-venue-name"><input type="text" placeholder="Nome do local" value={venueName} onChange={changeVenueNameHandle} /></div>
                        <div id="event-form-venue-street"><input type="text" placeholder="Endereço do local" value={venueStreet} onChange={changeVenueStreetHandle} /></div>
                    </> :
                    <>
                        <div id="event-form-venue-name">{venueName}</div>
                        <div id="event-form-venue-street">{venueStreet}</div>
                    </>
                }
            </div>
        </div>
        <div id="event-form-description">
            Descrição curta
            <textarea rows={5} />
            Descrição completa
            <textarea rows={12} />
        </div>
        <div id="event-form-cta">
            <MainButton content="Criar evento" onClick={onSubmit} />
        </div>
    </form>;
}

export { EventForm };