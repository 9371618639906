import React from "react";
import Moment from "moment";
import "moment/locale/pt-br";
import 'chartjs-adapter-moment';
import { DateTime } from "luxon";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale
} from 'chart.js';
import { Chart } from "react-chartjs-2";
import { TimeInterval } from "../../shared";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale
);

interface DataPoint {
    value: number,
    time: Date,
}

type Series = Array<DataPoint>;

interface SalesChartProps {
    series: Series,
    resolution: TimeInterval,
    isFinancial?: boolean,
    intervalStart?: number,
    intervalEnd?: number,
    width?: string | number,
    height?: string | number,
    onData?: (points: Array<number>) => void,
}

const SalesChart = ({ series, resolution, isFinancial, intervalStart, intervalEnd, width, height, onData }: SalesChartProps) => {

    Moment.locale("pt-br");

    const [currentHoverElement, setCurrentHoverElement] = React.useState<BarElement | null>(null);
    const [currentSelectedElements, setCurrentSelectedElements] = React.useState<Array<BarElement>>([]);

    const actualIntervalStart = intervalStart ?? DateTime.fromMillis(series.reduce((prev, cur) => Math.min(prev, cur.time.getTime()), Infinity)).startOf("day").toMillis();
    const actualIntervalEnd = intervalEnd ?? DateTime.fromMillis(series.reduce((prev, cur) => Math.max(prev, cur.time.getTime()), 0)).endOf("day").toMillis();

    const sendData = () => {
        if(onData !== undefined) {
            const data = currentSelectedElements.map(element => (element as any).$context.parsed.x);
            if(!!currentHoverElement) {
                data.push((currentHoverElement as any).$context.parsed.x);
            }
            onData(data);
        }
    }

    React.useEffect(sendData, [currentHoverElement]);
    React.useEffect(sendData, [currentSelectedElements]);

    const sortSeries = (series: Series) => {
        return series.sort((a, b) => {
            if(a.time.getTime() === b.time.getTime()) {
                return 0;
            } else if(a.time.getTime() > b.time.getTime()) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    const generateInterval = () => {
        const data: {[timeframe: number]: number} = {};
        const sortedSeries = sortSeries(series);
        for(let i = 0; i < sortedSeries.length; i++) {
            const point = sortedSeries[i];
            const time = point.time.getTime();
            if(time >= actualIntervalStart && time <= actualIntervalEnd) {
                const distanceFromStart = time - actualIntervalStart;            
                const timeframe = time - (distanceFromStart % resolution.ms);
                if(timeframe in data) {
                    data[timeframe] += point.value;
                } else {
                    data[timeframe] = point.value;
                }
            }
        }
        return data;
    }

    const generateDataset = (cumulative?: boolean) => {
        const data = [];
        const interval = generateInterval();
        const intervalKeys = Object.keys(interval);
        let cum = 0;

        for(let i = 0; i < intervalKeys.length; i++) {
            const timeframe = parseInt(intervalKeys[i]);
            if(cumulative) {
                cum += interval[timeframe];
                data.push({x: timeframe, y: cum});
            } else {
                data.push({x: timeframe, y: interval[timeframe]});
            }
        }
        return data;
    }

    const cumulativeDataset = generateDataset(true);
    const singleDataset = generateDataset();

    return <Chart
        width={width}
        height={height}
        onMouseOut={() => setCurrentHoverElement(null)}
        type="bar"
        options={{
            locale: "pt-br",
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: resolution.unit,
                        minUnit: "minute",
                        displayFormats: {
                            minute: "D MMM HH:mm",
                            hour: "D MMM HH:mm",
                            day: "D MMM YY",
                            month: "MMM YY",
                        },
                    },
                    ticks: {
                        stepSize: resolution.size,
                    },
                },
                single: {
                    position: "right",
                    ticks: {
                        callback: !!isFinancial ? (value) => Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(parseFloat(value.toString())) : undefined,
                    },
                },
                cumulative: {
                    position: "left",
                    ticks: {
                        callback: !!isFinancial ? (value) => Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(parseFloat(value.toString())) : undefined,
                    },
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: 'Free Event',
                },
                tooltip: {
                    callbacks: {
                        label: !!isFinancial ? (context) => {
                            let label = context.dataset.label || "";
                            if(!!label) {
                                label += ": ";
                            }
                            if(!!context.parsed.y) {
                                label += Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(context.parsed.y);
                            }
                            return label;
                        } : undefined,
                    },
                },
            },
            elements: {
                line: {
                    tension: 0.15,
                },
            },
            onHover: (_, elements) => {
                if(onData !== undefined) {
                    if(elements.length > 0) { 
                        const element = (elements[0].element as BarElement);
                        element.options.backgroundColor = "rgba(228, 4, 228, 0.75)";
                        setCurrentHoverElement(element);
                    } else {
                        setCurrentHoverElement(null);
                    }
                }
            },
            onClick: (_, elements) => {
                if(onData !== undefined) {
                    if(elements.length > 0) {
                        const element = (elements[0].element as BarElement);
                        if(currentSelectedElements.indexOf(element) !== -1) {
                            element.options.backgroundColor = "rgba(228, 4, 228, 0.75)";
                            setCurrentSelectedElements(oldElements => {
                                const index = oldElements.indexOf(element);
                                oldElements.splice(index, 1);
                                return oldElements;
                            });
                        } else {
                            element.options.backgroundColor = "rgb(228, 4, 228)";
                            setCurrentSelectedElements(oldElements => [...oldElements, element]);
                        }
                    }
                }
            }
        }}
        data={{
            datasets: [
                {
                    type: 'line',
                    label: 'Vendas acumuladas',
                    data: cumulativeDataset,
                    borderColor: "rgb(4, 228, 158)",
                    backgroundColor: "rgba(4, 228, 158, 0.5)",
                    yAxisID: "cumulative",
                },
                {
                    type: 'bar',
                    label: 'Vendas',
                    data: singleDataset,
                    borderColor: "rgb(228, 4, 228)",
                    backgroundColor: Array.from(Array(singleDataset.length).keys()).map(key => {
                        if(currentSelectedElements.some(element => (element as any).$context.dataIndex === key)) {
                            return "rgb(228, 4, 228)";
                        }
                        if(!!currentHoverElement && (currentHoverElement as any).$context.dataIndex === key) {
                            return "rgba(228, 4, 228, 0.75)";    
                        }
                        return "rgba(228, 4, 228, 0.5)";
                    }),
                    yAxisID: "single",
                }
            ]
        }} />
}

export { SalesChart };