import React from "react";
import "./button.scss";
import { AsyncMouseEventHandler } from "../../../shared";

enum ButtonType {
    Primary,
    Secondary
};

enum ButtonSize {
    Small,
    Large
};

export interface ButtonProps {
    enabled?: boolean,
    content: string | JSX.Element,
    onClick: AsyncMouseEventHandler<HTMLButtonElement>,
    onMouseOver?: AsyncMouseEventHandler<HTMLButtonElement>,
    onMouseOut?: AsyncMouseEventHandler<HTMLButtonElement>,
};

interface ExtendedButtonProps extends ButtonProps {
    type: ButtonType,
    size: ButtonSize,
};

const Button = (props: ExtendedButtonProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const enabled = props.enabled ?? true;

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(!loading) {
            setLoading(true);
            props.onClick(e).then(() => {
                setLoading(false);
            });
        }
        e.preventDefault();
    }

    const onMouseOver = () => {
        if(props.onMouseOver != undefined) {
            return props.onMouseOver;
        }
        return () => {};
    }

    const onMouseOut = () => {
        if(props.onMouseOut != undefined) {
            return props.onMouseOut;
        }
        return () => {};
    }

    return (
        <div className="form-button">
            <button className={[
                props.type === ButtonType.Primary ? "button-primary" : "button-secondary",
                props.size === ButtonSize.Large ? "button-large" : "button-small",
                enabled && !loading ? "" : "button-disabled"
            ].join(" ")} disabled={!enabled || loading} onClick={onClick} onMouseOver={onMouseOver()} onMouseOut={onMouseOut()}>
                {loading ? <div className="loading-progress"><div className="circle"></div></div> : props.content}
            </button>
        </div>
    );
};

const MainButton = (props: ButtonProps) => {
    return <Button enabled={props.enabled} content={props.content} type={ButtonType.Primary} size={ButtonSize.Large} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} />
};

const SecondaryButton = (props: ButtonProps) => {
    return <Button enabled={props.enabled} content={props.content} type={ButtonType.Secondary} size={ButtonSize.Large} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} />
};

const SmallButton = (props: ButtonProps) => {
    return <Button enabled={props.enabled} content={props.content} type={ButtonType.Primary} size={ButtonSize.Small} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} />
};

const SecondarySmallButton = (props: ButtonProps) => {
    return <Button enabled={props.enabled} content={props.content} type={ButtonType.Secondary} size={ButtonSize.Small} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} />
};

export { MainButton, SecondaryButton, SmallButton, SecondarySmallButton };