import React from "react";

type Dispatch<A> = (value: A) => void;
export type ThemeState = { theme: string, setTheme: Dispatch<React.SetStateAction<string>>}

const initialThemeState: ThemeState = {
    theme: "dark", //window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
    setTheme: () => {},
};

export const ThemeContext = React.createContext(initialThemeState);

export const ThemeProvider = ({ children } : { children: React.ReactNode}) => {
    const [theme, setTheme] = React.useState(initialThemeState.theme);

    const localStorage = window.localStorage;

    React.useEffect(() => {
        const storedTheme = localStorage.getItem("theme");

        if(!!storedTheme) {
            setTheme(storedTheme);
        }
    }, []);

    React.useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <div className={theme}>{children}</div>
        </ThemeContext.Provider>
    );
};