import React from "react";
import { Route, Routes } from "react-router-dom";
import { PassifyNavbar, ScreenLoader, SmallButton } from "./components";
import { AuthContext, NavigationContext, ThemeContext } from "./contexts";
import "./app.scss";
import { ArrowBack, ArrowBackIos, HeadsetMicOutlined } from "@mui/icons-material";

const App = () => {
    const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
    const [dismissDisclaimer, setDismissDisclaimer] = React.useState<boolean>(true);

    const { auth } = React.useContext(AuthContext);
    const { routeBuilder, currentView } = React.useContext(NavigationContext);
    const { theme } = React.useContext(ThemeContext);

    const init = () => {
        const dismissTOSDisclaimer = localStorage.getItem("dismissTOSDisclaimer");
        if(!dismissTOSDisclaimer) {
            setDismissDisclaimer(false);
        }
        setFirstLoad(false);
    }

    React.useEffect(init, []);

    const backCorner = (onClick: React.MouseEventHandler<HTMLAnchorElement>) => <a onClick={onClick}><div className="mobile"><ArrowBackIos /></div><div className="desktop"><ArrowBack sx={{ fontSize: "16px" }} />Voltar</div></a>;
    const supportCorner = (onClick: React.MouseEventHandler<HTMLAnchorElement>) => <a href="https://wa.me/5511956621753" onClick={onClick}><HeadsetMicOutlined sx={{fontSize: 18}} /></a>;

    const dismiss = async (e: React.MouseEvent<HTMLButtonElement>) => {
        localStorage.setItem("dismissTOSDisclaimer", "true");
        setDismissDisclaimer(true);
        e.preventDefault();
    }

    return (
        <main id="passify">
            {!!auth && !dismissDisclaimer && <div id="tos-disclaimer">
                <div id="tos-disclaimer-container">
                    <div id="tos-disclaimer-title">Bem vindo(a) à Passify!</div>
                    <div id="tos-disclaimer-text">Antes de continuar, certifique-se de ler os <a href="https://passify.co/termos-de-servico-produtor.pdf">termos de serviço</a>.<br />Você precisa estar de acordo para prosseguir.</div>
                    <div id="tos-disclaimer-button-container"><SmallButton content="Concordo com os termos" onClick={dismiss} /></div>
                </div>
            </div>}
            <header id="passify-header">
                {!!currentView && !!currentView.header && "backClick" in currentView.header && <>
                    <div className="passify-header-corner left">
                        {currentView.header.backClick !== null && backCorner(currentView.header.backClick)}
                    </div>
                    <h1><img id="app-logo" src={`/images/passify-${theme}.svg`} alt="Passify" width={120} height={32.4} /></h1>
                    <div className="passify-header-corner right">
                        {currentView.header.supportClick !== undefined && supportCorner(currentView.header.supportClick)}
                    </div>
                </>}
            </header>
            {/*<PassifyNavbar />*/}
            <div id="passify-body">
                {firstLoad || routeBuilder === undefined ? <ScreenLoader /> : <Routes>
                    <Route path="/">
                        {routeBuilder.build()}
                    </Route>
                </Routes>}
            </div>
        </main>     
    );
}

export { App };
