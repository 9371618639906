import { PassifyApi } from "../api";
import { executeRecaptcha } from "../recaptcha";

export const initFacebookSdk = async () => {
    return new Promise(resolve => {

        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: "772277337627657", // PROD
                //appId: "1009636003351953", // TEST
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });

            window.FB.AppEvents.logPageView();

            // auto authenticate with the api if already logged in with facebook
            window.FB.getLoginStatus(({ authResponse }) => {
                /*if (authResponse) {
                    executeRecaptcha(async (token) => {
                        const response = await PassifyApi.facebookSignin(token, authResponse.accessToken);
                        if(response.status == 200) {
                            const responseJSON = await response.json();
                            setAuth(responseJSON["access_token"], responseJSON["refresh_token"], true);
                        }
                    });
                }*/
                resolve();
            });
        };

        // load facebook sdk script
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}