import { Navigate, Route } from "react-router-dom";
import { View } from "./view";

type RouteTarget = View | string;
interface RouteNode {
	root?: RouteTarget,
	paths: {[path: string]: RouteNode | RouteTarget}
}

class RouteBuilder {
    private _views: Array<View>;
    private _routeTree: RouteNode;

    constructor(rootView: View, ...views: Array<View>) {
        this._views = views;
        this._routeTree = {
			root: rootView,
        	paths: {}
        }
		this._createRouteTree();
    }

	private _findFirstDefaultRoute = (node: RouteNode, fullPath?: string): string => {
		const nodeStack = [];
		for(let path in node.paths) {
			const currentFullPath = `${fullPath || ""}/${path}`;
			if(node.paths[path] instanceof View) {
				if((node.paths[path] as View).defaultRoute) {
					return currentFullPath;
				}
			} else if(!(typeof node.paths[path] === "string")) {
				const pushNode = node.paths[path] as RouteNode;
				if(pushNode.root != undefined && pushNode.root instanceof View) {
					if((pushNode.root as View).defaultRoute) {
						return currentFullPath;
					}
				}
				nodeStack.push({node: pushNode, fullPath: currentFullPath});
			}
		}

		for(let i = 0; i < nodeStack.length; i++) {
			const defaultRouteCandidate = this._findFirstDefaultRoute(nodeStack[i].node, nodeStack[i].fullPath);
			if(["/", nodeStack[i].fullPath].indexOf(defaultRouteCandidate) == -1) {
				return defaultRouteCandidate;
			}
		}

		return fullPath || "/";
	}

	private _optimizeNodes = (node: RouteNode, fullPath?: string) => {
		let noParamPath = true;

		for(let path in node.paths) {
			const currentFullPath = `${fullPath || ""}/${path}`;
			if(node.paths[path] instanceof View) {
				node.paths[path] = {
					root: node.paths[path] as View,
					paths: {
						"*": currentFullPath
					}
				}
			} else if(!(typeof node.paths[path] === "string")) {
				this._optimizeNodes(node.paths[path] as RouteNode, currentFullPath);
			}

			if(path.indexOf(":") == 0) {
				noParamPath = false;
			}
		}

		if(noParamPath) {
			if(node.root == undefined) {
				node.paths["*"] = this._findFirstDefaultRoute(node, fullPath);
				node.root = node.paths["*"];
			} else {
				node.paths["*"] = fullPath || "/";
			}
		}
	}

    private _createRouteTree = () => {
		for(let i = 0; i < this._views.length; i++) {
			const view = this._views[i];
			const routeParts = view.route.substring(1).split("/");
			
			let pathCursor = this._routeTree;
			for(let j = 0; j < routeParts.length; j++) {
				const routePart = routeParts[j];
				const lastRoutePart = (j == routeParts.length - 1);
				const pathExists = routePart in pathCursor.paths;

				if(pathExists) {
					const isNode = !(pathCursor.paths[routePart] instanceof View || typeof pathCursor.paths[routePart] === "string");
					if(isNode) {
						pathCursor = pathCursor.paths[routePart] as RouteNode;
						if(lastRoutePart) {
							pathCursor.root = view;
						}
					} else {
						if(lastRoutePart) {
							pathCursor.paths[routePart] = view;
						} else {
							pathCursor.paths[routePart] = {
								root: pathCursor.paths[routePart] as RouteTarget,
								paths: {}
							}
							pathCursor = pathCursor.paths[routePart] as RouteNode;
						}
					}
				} else {
					if(lastRoutePart) {
						pathCursor.paths[routePart] = view;  
					} else {
						pathCursor.paths[routePart] = { paths: {} };
						pathCursor = pathCursor.paths[routePart] as RouteNode;
					}
				}
			}
		}

		this._optimizeNodes(this._routeTree);

		return this._routeTree;
    }

    private _parseRoute = (node: RouteNode) => {
        const pathRoutes = [];
        for(let path in node.paths) {
			const childNode = node.paths[path];
			if(childNode instanceof View) {
				pathRoutes.push(<Route key={path} path={path} element={<childNode.render />} />);
			} else if(typeof childNode === "string") {
				pathRoutes.push(<Route key={path} path={path} element={<Navigate to={childNode} />} />);
			} else {
				pathRoutes.push(<Route key={path} path={path}>{this._parseRoute(childNode)}</Route>);
			}
        }
        return <>
			{node.root != undefined && (node.root instanceof View ? <Route index element={<node.root.render />} /> : <Route index element={<Navigate to={node.root} />} />)}
			{pathRoutes}
        </>;
	}

	find = (path: string) => {
		let currentNode: RouteNode = this._createRouteTree();

		if(path === "/" && currentNode.root !== undefined) {
			return currentNode.root;
		}

		const pathParts = path.substring(1).split("/");
		for(let i = 0; i < pathParts.length; i++) {
			let pathPart = pathParts[i].split("?")[0];
			const pathExists = pathPart in currentNode.paths;

			let isNode = false;
			if(!pathExists) {
				let paramPath = undefined;
				for(let path in currentNode.paths) {
					if(path[0] === ":") {
						paramPath = path;
						break;
					}
				}

				if(paramPath === undefined) {
					return "/";
				} else {
					pathPart = paramPath;
				}
			}

			isNode = !(currentNode.paths[pathPart] instanceof View || typeof currentNode.paths[pathPart] === "string");
			if(isNode) {
				currentNode = currentNode.paths[pathPart] as RouteNode;
			} else {
				return currentNode.paths[pathPart] as RouteTarget;
			}
		}

		if(currentNode.root != undefined) {
			return currentNode.root;
		} else {
			return "/";
		}
	}

	build = () => {
		return this._parseRoute(this._createRouteTree());
	}
}

export { RouteBuilder };