'use client'
import React from "react";
import { clsx } from "clsx";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "./style.scss";

interface PaginationStyle extends React.CSSProperties {
    "--size": string
}

interface PaginationProps {
    showText?: boolean,
    currentPage: number,
    pageCount: number,
    style?: PaginationStyle,
    onChange: (page: number) => void,
}

const Pagination = ({showText, currentPage, pageCount, onChange, style} : PaginationProps) => {
    style = style ?? { "--size": "420px" }

    const changePage = () => {
        if(onChange !== undefined) {
            
        }
    }

    React.useEffect(changePage, [currentPage]);

    const prev = (e: React.MouseEvent<HTMLDivElement>) => {
        if(currentPage > 0) {
            onChange(currentPage - 1);
        }
        e.preventDefault();
    }

    const next = (e: React.MouseEvent<HTMLDivElement>) => {
        if(currentPage < pageCount - 1) {
            onChange(currentPage + 1);
        }
        e.preventDefault();
    }

    const goToPage = (page: number) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            if(page >= 0 && page < pageCount) {
                onChange(page);
            }
            e.preventDefault();
        }
    }

    return <nav className={clsx({
        "pagination": true,
        "show-text": showText,
    })} style={style}>
        <div className={clsx({
            "pagination-prev": true,
            "disabled": currentPage === 0,
        })} onClick={prev}><ArrowBackIos sx={{fontSize: "14px"}} />{showText && " Anterior"}</div>
        <div className="pagination-separator"></div>
        <div className="pagination-track">
            <div className="pagination-track-body">
                {Array.from(Array(pageCount).keys()).map(page =>
                    <div key={page} className={clsx({
                        "pagination-track-current": page === currentPage,
                        "pagination-track-item": true,
                    })} style={{transform: `translateX(calc(var(--cursor-width) * ${-currentPage} + (var(--track-width) - var(--cursor-width)) * 0.5))`}} onClick={goToPage(page)}>{page + 1}</div>
                )}
            </div>
        </div>
        <div className="pagination-separator"></div>
        <div className={clsx({
            "pagination-next": true,
            "disabled": currentPage === pageCount - 1,
        })} onClick={next}>{showText && "Próxima "}<ArrowForwardIos sx={{fontSize: "14px"}} /></div>
    </nav>;
}

export { Pagination };