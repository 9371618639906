import React from "react";
import { ThemeContext } from "../../contexts";
import "./screenloader.scss";

const ScreenLoader = () => {
    const { theme } = React.useContext(ThemeContext);

    return <div className="screen-loader">
        <div><img className="screen-loader-logo" src={`/images/passify-${theme}.svg`} alt="Passify" /></div>
        <div className="screen-loader-progressbar"><div className="screen-loader-progressbar-circle"></div></div>
    </div>;
}

export { ScreenLoader };