import { View } from "./view";

export interface RouteParams {
    [key: string]: string
}

class RouteNavigator {
    static ParseRoute = (section: View, params?: RouteParams, searchParams?: RouteParams) => {
        let finalRoute = section.route;
        if(params !== undefined) {
            for(let key in params) {
                const pattern = RegExp(`\:${key}`, "g");
                finalRoute = finalRoute.replace(pattern, params[key]);
            }
        }
        if(searchParams !== undefined) {
            finalRoute += "?";
            for(let key in searchParams) {
                finalRoute += `${key}=${encodeURIComponent(searchParams[key])}`;
            }
        }
        return finalRoute;
    }
}

export { RouteNavigator };