import "./checkboxinput.scss";

export interface CheckboxInputProps {
    id: string,
    name: string,
    checked: boolean,
    text: string | JSX.Element,
    onClick: React.MouseEventHandler<HTMLInputElement>,
};

const CheckboxInput = (props: CheckboxInputProps) => {
    return (
        <label htmlFor={props.id}>
            <div className="form-input-checkbox">
                <div className="checkbox-container">
                    <input type="checkbox" id={props.id} name={props.name} defaultChecked={props.checked} onClick={props.onClick} />
                    {!props.checked ?
                        <div className="uncheckedbox"></div> :
                        <div className="checkedbox">
                            <div className="checkmark"></div>
                        </div>
                    }
                </div>
                {props.text}
            </div>
        </label>
    );
};

export { CheckboxInput };