import React from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { AccessTime, Close, GridView } from "@mui/icons-material";
import { BatchInput, EVENTS_ID, EventDetails, SegmentInput, TICKETS_CONFIGURATION_ID, View } from "../../shared";
import { ButtonSliderSwitch, Calendar, CheckboxInput, MainButton, ScreenLoader, SmallButton, TextInput } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import "./ticketsconfigurationview.scss";

enum TicketsConfigurationDisplay {
    timeline,
    blocks,
}

const jsDateToString = (date: Date) => {
    return DateTime.fromJSDate(new Date(date.getTime() + date.getTimezoneOffset() * 60000)).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
}

let firstLoad = true;
let save = false;

class TicketsConfigurationView extends View {
    id = TICKETS_CONFIGURATION_ID;
    route = "/event/:eventId/tickets";
	defaultRoute = false;
	authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENTS_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [event, setEvent] = React.useState<EventDetails>();
        const [display, setDisplay] = React.useState<TicketsConfigurationDisplay>(TicketsConfigurationDisplay.blocks);
        const [segments, setSegments] = React.useState<Array<SegmentInput>>([]);
        const [newSegment, setNewSegment] = React.useState<string>("");
        const [newBatch, setNewBatch] = React.useState<{[segmentId: string]: string}>({});
        const [editingBatch, setEditingBatch] = React.useState<BatchInput>();
        const [editStartDate, setEditStartDate] = React.useState<boolean>(false);
        const [startDate, setStartDate] = React.useState<Date>(new Date());
        const [editStartTime, setEditStartTime] = React.useState<boolean>(false);
        const [editEndDate, setEditEndDate] = React.useState<boolean>(false);
        const [endDate, setEndDate] = React.useState<Date>(new Date());
        const [editEndTime, setEditEndTime] = React.useState<boolean>(false);
        const [quantity, setQuantity] = React.useState<string>("");
        const [price, setPrice] = React.useState<string>("");
        const [showPublic, setShowPublic] = React.useState<boolean>(false);
        const [overflow, setOverflow] = React.useState<boolean>(false);

        const params = this.params = useParams();
        const api = React.useContext(ApiContext);
        this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {

            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return (error: any) => {
                return response;
            };
        }

        const closeAll = (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartDate(false);
            setEditStartTime(false);
            setEditEndDate(false);
            setEditEndTime(false);
            e.preventDefault();
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.event.getEventDetails(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(undefined),
            });
        }

        const loadTicketConfiguration = async (eventId: string, sessionId: string) => {
            const failableTicketConfiguration = await api.producer.getTicketsConfiguration(eventId, sessionId);
            return failableTicketConfiguration.match({
                success: config => config,
                failure: handleError(null),
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(event => {
                setEvent(event);
                loadTicketConfiguration(eventId, event?.sessions?.[0]?.id ?? "").then(config => {
                    setSegments(config?.segments ?? []);
                    firstLoad = false;
                });
            })
        }

        const loadForm = () => {
            if(!!editingBatch) {
                setStartDate(new Date(Date.parse(editingBatch.startSale)));
                setEndDate(new Date(Date.parse(editingBatch.endSale)));
                setQuantity(editingBatch.quantity.toString());
                setPrice(editingBatch.price.toString());
                setShowPublic(editingBatch.visible);
            }
        }

        const saveConfig = () => {
            if(save && !firstLoad) {
                api.producer.saveTicketsConfiguration(event?.id ?? "", event?.sessions?.[0]?.id ?? "", { customerLimit: 4, segments }).then(configFailable => {
                    configFailable.match({
                        success: newConfig => {
                            setSegments(newConfig?.segments ?? []);
                            setEditingBatch(undefined);
                        },
                        failure: () => {}
                    });
                    save = false;
                });
            }
        }

        React.useEffect(init, []);
        React.useEffect(loadForm, [editingBatch]);
        React.useEffect(saveConfig, [segments]);

        const changeNewSegmentHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
            setNewSegment(e.currentTarget.value);
            e.preventDefault();
        }

        const addNewSegment = async (e: React.MouseEvent<HTMLButtonElement>) => {
            save = true;
            setSegments(oldSegments => [...oldSegments, {id: null, name: newSegment, displayName: newSegment, batches: []}]);
            setNewSegment("");
            e.preventDefault();
        }

        const removeSegment = (segmentId: string | null) => {
            return (e: React.MouseEvent<HTMLButtonElement>) => {
                if(window.confirm(`Tem certeza que deseja deletar este segmento?`)) {
                    save = true;
                    setSegments(oldSegments => oldSegments.filter(segment => segment.id !== segmentId));
                }
                e.preventDefault();
            }
        }

        const changeNewBatchHandle = (segmentId: string) => {
            return (e: React.ChangeEvent<HTMLInputElement>) => {
                setNewBatch(oldNewBatch => ({...oldNewBatch, [segmentId]: e.currentTarget?.value ?? e.target?.value ?? ""}));
                e.preventDefault();
            }
        }

        const addNewBatch = (segmentId: string) => {
            return async (e: React.MouseEvent<HTMLButtonElement>) => {
                save = true;
                setSegments(oldSegments => oldSegments.map(segment => segment.id !== segmentId ? segment : {...segment, batches: [...segment.batches, {id: null, name: newBatch[segmentId || ""] ?? "", displayName: newBatch[segmentId || ""] ?? "", startSale: jsDateToString(new Date()), endSale: jsDateToString(new Date()), quantity: 0, price: 0, visible: true, quantityUsed: 0}]}));
                setNewBatch(oldNewBatches => ({...oldNewBatches, [segmentId]: ""}));
                e.preventDefault();
            }
        }

        const removeBatch = (segmentId: string | null, batchId: string | null) => {
            return (e: React.MouseEvent<HTMLButtonElement>) => {
                if(window.confirm(`Tem certeza que deseja deletar este lote?`)) {
                    save = true;
                    setSegments(oldSegments => oldSegments.map(segment => segment.id !== segmentId ? segment : {...segment, batches: segment.batches.filter(batch => batch.id !== batchId)}));
                }
                e.preventDefault();
            }
        }

        const openStartDateCalendar = (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartTime(false);
            setEditEndDate(false);
            setEditEndTime(false);
            setEditStartDate(oldValue => !oldValue);
            e.preventDefault();
        }

        const changeStartDateHandle = (date: Date) => {
            setStartDate(oldDate => new Date(date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes(), 0));
        }
    
        const openStartTimeSelector = (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartDate(false);
            setEditEndDate(false);
            setEditEndTime(false);
            setEditStartTime(oldValue => !oldValue);
            e.preventDefault();
        }
    
        const changeStartTimeHandle = (hour: number, minute: number) => {
            return (e: React.MouseEvent<HTMLDivElement>) => {
                setEditStartTime(false);
                if(!!editingBatch) {
                    const start = new Date(Date.parse(editingBatch.startSale));
                    setEditingBatch(oldBatch => ({...oldBatch!, startSale: jsDateToString(new Date(start.getFullYear(), start.getMonth(), start.getDate(), hour, minute, 0))}));
                }
                e.preventDefault();
            }
        }
    
        const openEndDateCalendar = (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartDate(false);
            setEditStartTime(false);
            setEditEndTime(false);
            setEditEndDate(oldValue => !oldValue);
            e.preventDefault();
        }

        const changeEndDateHandle = (date: Date) => {
            setEndDate(oldDate => new Date(date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes(), 0));
        }
    
        const openEndTimeSelector = (e: React.MouseEvent<HTMLDivElement>) => {
            setEditStartDate(false);
            setEditStartTime(false);
            setEditEndDate(false);
            setEditEndTime(oldValue => !oldValue);
            e.preventDefault();
        }
    
        const changeEndTimeHandle = (hour: number, minute: number) => {
            return (e: React.MouseEvent<HTMLDivElement>) => {
                setEditEndTime(false);
                if(!!editingBatch) {
                    const end = new Date(Date.parse(editingBatch.endSale));
                    setEditingBatch(oldBatch => ({...oldBatch!, endSale: jsDateToString(new Date(end.getFullYear(), end.getMonth(), end.getDate(), hour, minute, 0))}));
                }
                e.preventDefault();
            }
        }

        const changeQuantityHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
            setQuantity(e.currentTarget.value);
            e.preventDefault();
        }

        const changePriceHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
            setPrice(e.currentTarget.value);
            e.preventDefault();
        }

        const changeShowPublicHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setShowPublic(old => !old);
            e.preventDefault();
        }

        const changeOverflowHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setOverflow(old => !old);
            e.preventDefault();
        }

        const updateBatch = async (e: React.MouseEvent<HTMLButtonElement>) => {
            save = true;
            setSegments(oldSegments => oldSegments.map(segment => ({...segment, batches: segment.batches.map(batch => {
                if(batch.id === editingBatch?.id) {
                    return {
                        ...editingBatch!,
                        name: editingBatch.name,
                        displayName: editingBatch.displayName,
                        startSale: jsDateToString(startDate),
                        endSale: jsDateToString(endDate),
                        quantity: parseInt(quantity),
                        //oversellQuantity?: number,
                        price: parseInt(price),
                        visible: showPublic,
                        quantityUsed: editingBatch.quantityUsed,
                    };
                }
                return batch;
            })})));
            setNewSegment("");
            e.preventDefault();
        }

		return (firstLoad ?
			<ScreenLoader /> : <div id="tickets-configuration">
                <div>
                    <ButtonSliderSwitch index={display === TicketsConfigurationDisplay.blocks ? 1 : 0} items={[{
                        content: <AccessTime sx={{height: 16}} />,
                        onClick: (e) => {
                            setDisplay(TicketsConfigurationDisplay.timeline);
                            e.preventDefault();
                        }
                    },{
                        content: <GridView sx={{height: 16}} />,
                        onClick: (e) => {
                            setDisplay(TicketsConfigurationDisplay.blocks);
                            e.preventDefault();
                        }
                    }]} />
                </div>
                {display === TicketsConfigurationDisplay.timeline ?
                    <></> :
                    <div id="tickets-configuration-segments">
                        <div id="tickets-configuration-segments-new">
                            <TextInput id="tickets-configuration-segments-new-input" name="tickets-configuration-segments-new" placeholder="Novo segmento" value={newSegment} onChange={changeNewSegmentHandle} />
                            <SmallButton content="Adicionar" onClick={addNewSegment} />
                        </div>
                        {segments.map(segment => <div key={segment.id || v4()} className="tickets-configuration-segment">
                            <div className="tickets-configuration-segment-name">
                                {segment.name}
                                <button className="close" onClick={removeSegment(segment.id)}><Close sx={{height: 16}} /></button>
                            </div>
                            {segment.batches.map(batch => <div key={batch.id || v4()} className="tickets-configuration-batch">
                                <div className="tickets-configuration-batch-name" onClick={() => { setEditingBatch(batch); }}>{batch.name}</div>
                                <button className="close" onClick={removeBatch(segment.id, batch.id)}><Close sx={{height: 16}} /></button>
                            </div>)}
                            <div className="tickets-configuration-batches-new">
                                <TextInput id={`tickets-configuration-batches-new-input-${segment.id || v4()}`} name={`tickets-configuration-batches-new-${segment.id || v4()}`} placeholder="Novo lote" value={newBatch[segment.id || ""] ?? ""} onChange={changeNewBatchHandle(segment.id || v4())} />
                                <SmallButton content="Adicionar" onClick={addNewBatch(segment.id || v4())} />
                            </div>
                        </div>)}
                    </div>
                }
                {!!editingBatch && <div id="tickets-configuration-editing-batch">
                    <div onClick={() => { setEditingBatch(undefined); }}></div>
                    <div id="tickets-configuration-editing-batch-container">
                        {(editStartDate || editEndDate) && <div id="tickets-configuration-editing-batch-form-click-out" onClick={closeAll}></div>}
                        <div id="tickets-configuration-editing-batch-name">{editingBatch.name}</div>
                        <div id="tickets-configuration-editing-batch-form-date">
                            <div id="tickets-configuration-editing-batch-form-date-container">
                                <div id="tickets-configuration-editing-batch-form-date-start">
                                    <span>Início</span>
                                    <div className="tickets-configuration-editing-batch-form-date-input">
                                        <div>
                                            <div onClick={openStartDateCalendar}>
                                                {DateTime.fromJSDate(startDate).toFormat("dd/LL/yyyy", { locale: "pt-br" })}
                                            </div>
                                            <div className={`tickets-configuration-editing-batch-form-date-calendar ${editStartDate ? "open" : ""}`}>
                                                <Calendar selectedDate={startDate} onChange={changeStartDateHandle} />
                                            </div>
                                        </div>
                                        <div>
                                            <div onClick={openStartTimeSelector}>
                                                {DateTime.fromJSDate(startDate).toFormat("HH:mm", { locale: "pt-br" })}
                                            </div>
                                            <div className={`tickets-configuration-editing-batch-form-date-time-select ${editStartTime ? "open" : ""}`}>
                                                {Array.from(Array(24).keys()).map(hour => <>
                                                    <div key={`start-${hour}-0`} className={startDate.getHours() === hour && startDate.getMinutes() === 0 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 0)}>{hour < 10 ? `0${hour}` : hour}:00</div>
                                                    <div key={`start-${hour}-15`} className={startDate.getHours() === hour && startDate.getMinutes() === 15 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 15)}>{hour < 10 ? `0${hour}` : hour}:15</div>
                                                    <div key={`start-${hour}-30`} className={startDate.getHours() === hour && startDate.getMinutes() === 30 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 30)}>{hour < 10 ? `0${hour}` : hour}:30</div>
                                                    <div key={`start-${hour}-45`} className={startDate.getHours() === hour && startDate.getMinutes() === 45 ? "selected" : ""} onClick={changeStartTimeHandle(hour, 45)}>{hour < 10 ? `0${hour}` : hour}:45</div>
                                                </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tickets-configuration-editing-batch-form-date-end">
                                    <span>Fim</span>
                                    <div className="tickets-configuration-editing-batch-form-date-input">
                                        <div>
                                            <div onClick={openEndDateCalendar}>
                                                {DateTime.fromJSDate(endDate).toFormat("dd/LL/yyyy", { locale: "pt-br" })}
                                            </div>
                                            <div className={`tickets-configuration-editing-batch-form-date-calendar ${editEndDate ? "open" : ""}`}>
                                                <Calendar selectedDate={endDate} onChange={changeEndDateHandle} />
                                            </div>
                                        </div>
                                        <div>
                                            <div onClick={openEndTimeSelector}>
                                                {DateTime.fromJSDate(endDate).toFormat("HH:mm", { locale: "pt-br" })}
                                            </div>
                                            <div className={`tickets-configuration-editing-batch-form-date-time-select ${editEndTime ? "open" : ""}`}>
                                            {Array.from(Array(24).keys()).map(hour => <>
                                                    <div key={`end-${hour}-0`} className={endDate.getHours() === hour && endDate.getMinutes() === 0 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 0)}>{hour < 10 ? `0${hour}` : hour}:00</div>
                                                    <div key={`end-${hour}-15`} className={endDate.getHours() === hour && endDate.getMinutes() === 15 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 15)}>{hour < 10 ? `0${hour}` : hour}:15</div>
                                                    <div key={`end-${hour}-30`} className={endDate.getHours() === hour && endDate.getMinutes() === 30 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 30)}>{hour < 10 ? `0${hour}` : hour}:30</div>
                                                    <div key={`end-${hour}-45`} className={endDate.getHours() === hour && endDate.getMinutes() === 45 ? "selected" : ""} onClick={changeEndTimeHandle(hour, 45)}>{hour < 10 ? `0${hour}` : hour}:45</div>
                                                </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tickets-configuration-editing-batch-form-row">
                            <div id="tickets-configuration-editing-batch-form-quantity">
                                Quantidade de ingressos
                                <TextInput id="tickets-configuration-editing-batch-form-quantity-input" name="tickets-configuration-editing-batch-form-quantity" placeholder="" value={quantity} onChange={changeQuantityHandle} />
                            </div>
                            <div id="tickets-configuration-editing-batch-form-price">
                                Preço
                                <TextInput id="tickets-configuration-editing-batch-form-price-input" name="tickets-configuration-editing-batch-form-price" placeholder="" value={price} enabled={editingBatch.quantityUsed === 0} onChange={changePriceHandle} />
                            </div>
                        </div>
                        <div className="tickets-configuration-editing-batch-form-row">
                            <div id="tickets-configuration-editing-batch-form-public">
                                <CheckboxInput id="tickets-configuration-editing-batch-form-public-input" name="tickets-configuration-editing-batch-form-quantity" text="Exibir publicamente" checked={showPublic} onClick={changeShowPublicHandle} />
                            </div>
                            <div id="tickets-configuration-editing-batch-form-overflow">
                                <CheckboxInput id="tickets-configuration-editing-batch-form-overflow-input" name="tickets-configuration-editing-batch-form-price" text="Transbordar para próximo lote" checked={overflow} onClick={changeOverflowHandle} />
                            </div>
                        </div>
                        <MainButton content="Salvar" onClick={updateBatch} />
                    </div>
                </div>}
			</div>
		);
	}
}

export { TicketsConfigurationView };