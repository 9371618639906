import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initFacebookSdk, initRecaptchaSdk } from './shared';
import { PassifyProvider } from './contexts';
import { App } from './app';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function startApp() {
  root.render(
    <GoogleOAuthProvider clientId="528737513271-sujbe12bafme3mer389k3ees5ohn963s.apps.googleusercontent.com">
      <React.StrictMode>
        <BrowserRouter>
          <PassifyProvider>
            <App />
          </PassifyProvider>
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}

initRecaptchaSdk().then(() => { initFacebookSdk().then(startApp); });



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
