import * as XLSX from "xlsx";

export const exportToSpreadsheet = (data: {[sheetName: string]: unknown[][]}, fileName: string) => {
	const book: XLSX.WorkBook = {
		Sheets: {},
		SheetNames: [],
	};

	for(let sheetName in data) {
		const sheet = XLSX.utils.aoa_to_sheet(data[sheetName]);
		book.Sheets[sheetName] = sheet;
		book.SheetNames.push(sheetName);
	}
	
	XLSX.writeFile(book, `${fileName}.xlsx`);
};