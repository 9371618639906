import React from "react";
import { ApiProvider } from "./api";
import { AuthProvider } from "./auth";
import { CustomerProvider } from "./producer";
import { NavigationProvider } from "./navigation";
import { ThemeProvider } from "./theme";
import { NotificationsProvider } from "./notifications";

const PassifyProvider = ({ children } : { children: React.ReactNode}) => (
    <NotificationsProvider>
        <AuthProvider>
            <ApiProvider>
                <CustomerProvider>
                        <NavigationProvider>
                            <ThemeProvider>
                                {children}
                            </ThemeProvider>
                        </NavigationProvider>
                </CustomerProvider>
            </ApiProvider>
        </AuthProvider>
    </NotificationsProvider>
);

export { PassifyProvider };