import React from "react";
import { useParams } from "react-router-dom";
import { ApiProducerError, EDIT_EVENT_ID, EVENTS_ID, EventDetails, SIGNIN_ID, TICKETS_CONFIGURATION_ID, View } from "../../shared";
import { ScreenLoader, SecondaryButton } from "../../components";
import { EventForm } from "./eventform";
import { ApiContext, NavigationContext } from "../../contexts";
import "./editeventview.scss";

class EditEventView extends View {
    id = EDIT_EVENT_ID;
    route = "/event/:eventId/edit";
	defaultRoute = false;
	authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENTS_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [event, setEvent] = React.useState<EventDetails>();

        const params = this.params = useParams();

        const api = React.useContext(ApiContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {
                goTo(views[EVENTS_ID]);
            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return (error: ApiProducerError) => {
                switch(error.errorType) {
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                        break;
                }
                return response;
            }
        }

        const loadEvent = async (eventId: string) => {
            const failableSales = await api.producer.getEvent(eventId);
            return failableSales.match({
                success: event => event,
                failure: handleError(null)
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(event => {
                if(!!event) {
                    setEvent(event);
                    setFirstLoad(false);
                } else {
                    goTo(views[EVENTS_ID]);
                }
            });
        }

        React.useEffect(init, []);

        const updateEvent = async () => {

        }

		return (firstLoad ?
			<ScreenLoader /> : <div id="edit-event">
                <EventForm name={event?.name} bigImageUrl={event?.bigImageUrl} startDate={event?.startDate} endDate={event?.endDate} venue={{ name: event?.venue?.name, street: event?.venue?.street1 }} shortDescription={event?.shortDescription} longDescription={event?.longDescription} onSubmit={updateEvent} />
                <SecondaryButton content="Configurar ingressos" onClick={async () => { goTo(views[TICKETS_CONFIGURATION_ID], getIds()); }} />
			</div>
		);
	}
}

export { EditEventView };