import "./textinput.scss";

interface TextInputIconProps {
    imageIconUrl: string,
    width?: number,
    height?: number,
};

export interface TextInputProps {
    ref?: React.RefObject<HTMLInputElement>,
    id: string,
    name: string,
    password?: boolean,
    inputMode?: "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal",
    placeholder: string,
    value: string,
    prefix?: JSX.Element,
    suffix?: JSX.Element,
    enabled?: boolean,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onFocus?: React.FocusEventHandler<HTMLInputElement>,
    onBlur?: React.FocusEventHandler<HTMLInputElement>,
};

const TextInput = (props: TextInputProps) => {

    return (
        <label htmlFor={props.id}>
            <div className="form-input-text">
                {props.prefix ?? ""}
                <input ref={props.ref} type={props.password ? "password" : "text"} id={props.id} name={props.name} inputMode={props.inputMode} value={props.value} placeholder={props.placeholder} disabled={props.enabled != undefined ? !props.enabled : false} onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur} />
                {props.suffix ?? ""}
            </div>
        </label>
    );
};

export { TextInput };