import React from "react";
import { SyncMouseEventHandler } from "../../shared";
import "./buttonsliderswitch.scss";

interface ButtonSliderSwitchItem {
    content: JSX.Element | string,
    onClick?: SyncMouseEventHandler<HTMLButtonElement>
}

interface ButtonSliderSwitchProps {
    index?: number,
    items: Array<ButtonSliderSwitchItem>
}

const ButtonSliderSwitch = (props: ButtonSliderSwitchProps) => {
    const [currentIndex, setCurrentIndex] = React.useState<number>(props.index || 0);
    const highlightRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const highlight = highlightRef.current;
        if(!!highlight) {
            highlight.style.left = `calc(${currentIndex} * (100% / var(--switch-length)))`;
        }
    }, [currentIndex]);

    const onClick = (item: ButtonSliderSwitchItem) => {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            if(item.onClick !== undefined) {
                item.onClick(e);
            }
            setCurrentIndex(props.items.indexOf(item));
            e.preventDefault();
        }
    }

    return <div className="button-slider-switch" style={{"--switch-length": props.items.length} as React.CSSProperties}>
        <div className="button-slider-switch-container">
            {props.items.map(item => <div className="button-slider-switch-item">
                <button className={props.items[currentIndex] === item ? "selected" : ""} onClick={onClick(item)}>{item.content}</button>
            </div>)}
            <div ref={highlightRef} className="button-slider-switch-highlight"></div>
        </div>
    </div>
};

export { ButtonSliderSwitch };