import React from "react";
import { Pin } from "@mui/icons-material";
import { FORGOT_PASSWORD_ID, SIGNIN_ID, View, RESET_PASSWORD_ID, EVENTS_ID } from "../../shared";
import { MainButton, PasswordForm, PasswordRequirement, ScreenLoader, TextInput } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import "./verificationview.scss";

class ResetPasswordView extends View {
    id = RESET_PASSWORD_ID;
    route = "/auth/reset-password";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[FORGOT_PASSWORD_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [email, setEmail] = React.useState<string>();
        const [code, setCode] = React.useState<string>("");
        const [password, setPassword] = React.useState<string>();
        const [confirmPassword, setConfirmPassword] = React.useState<string>("");
        const [requirementsFulfilled, setRequirementsFulfilled] = React.useState<boolean>(false);
        const [showPasswordRequirements, setShowPasswordRequirements] = React.useState<boolean>(false);
        const [waitForClose, setWaitForClose] = React.useState<boolean>(false);

        const api = React.useContext(ApiContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        // hardcoded sessionIds TODO: Remove
        const freeEvent = "6646f1db-9759-4c7d-aba6-b0af3ef93ae1";
        const festivalRock = "87b12fed-03d7-484f-bc75-a57d11965672";
        const classique = "ff645e53-b6f0-4cb2-acff-dfa59f47cc5e";
        const selectedSessionId = classique;

        React.useEffect(() => {
            const rawRegisterData = sessionStorage.getItem("register");
            if(rawRegisterData == null) {
                navigate(-1);
            } else {
                const registerData = JSON.parse(rawRegisterData);
                setEmail(registerData["email"]);
            }
            setFirstLoad(false);
        }, []);

        React.useEffect(() => {
            if(waitForClose) {
                toggleRequirements(false);
            }
        }, [password, confirmPassword]);

        const changeCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const codeCandidate = e.currentTarget.value;
            if(codeCandidate.length <= 6 && (codeCandidate === "" || !isNaN(parseInt(codeCandidate)))) {
                setCode(codeCandidate);
            }
            e.preventDefault();
        }

        const toggleRequirements = (focus: boolean) => {
            if(focus) {
                setShowPasswordRequirements(true);
                setWaitForClose(false);
            } else if(requirementsFulfilled) {
                setShowPasswordRequirements(false);
                setWaitForClose(false);
            } else {
                setWaitForClose(true);
            }
        }

        const confirmResetPassword = async () => {
            const failablePasswordReset = await api.auth.resetPassword(email!.trim(), code, password!, false);
            failablePasswordReset.match({
                success: passwordReset => {
                    if(passwordReset) {
                        sessionStorage.removeItem("register");
                        window.alert("Senha alterada com sucesso!");
                        goTo(views[EVENTS_ID]);
                    } else {
                        window.alert("Código de verificação inválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID]);
                            break;
                        case "FETCH_GENERIC_ERROR":
                            window.alert("Código de verificação inválido.");
                            break;
                    }
                }
            });
        }

        const verify = async (e: React.MouseEvent<HTMLButtonElement>) => {
            await confirmResetPassword();
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="verification">
            <div id="verification-explanation">
                Enviamos um código para seu e-mail<br /><b>{email}</b><br /><br /><small>(<a onClick={() => goTo(views[FORGOT_PASSWORD_ID])}>Esse não é o meu e-mail</a>)</small><br /><br />Para resetar sua senha, insira-o na campo abaixo para validá-lo e crie uma nova senha:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" inputMode="numeric" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <PasswordForm showLabels={true} onPasswordChanged={setPassword} onConfirmPasswordChanged={setConfirmPassword} onFocus={toggleRequirements} showPasswordRequirements={showPasswordRequirements} required={[PasswordRequirement.length]} recommended={[PasswordRequirement.lowercase, PasswordRequirement.uppercase, PasswordRequirement.digit, PasswordRequirement.specialchar]} onRequirementsChanged={setRequirementsFulfilled} />
            <div className="verification-button-container">
                <MainButton enabled={requirementsFulfilled && code.length === 6} content="Resetar senha" onClick={verify} />
            </div>
        </div>
    }
}

export { ResetPasswordView };