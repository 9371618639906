interface TextEditingValue {
    text: string,
    cursor: number,
}

abstract class MaskFormatter {
    abstract unmask(maskedText: string): string;
    abstract mask(unmaskedText: string): string | null;

    updateValue(oldValue: TextEditingValue, newValue: TextEditingValue) {
        const unmaskedText = this.unmask(newValue.text);
        const maskedText = this.mask(unmaskedText);

        return maskedText !== null ? {
            text: maskedText,
            cursor: newValue.cursor + (maskedText.length - newValue.text.length),
        } : oldValue;
    }

}

export { MaskFormatter };